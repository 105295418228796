import {TextField} from "@mui/material";
import {useState} from "react";
import i18n from "../i18n";

const ErrorHandlingTextField = ({validators, onValChange, check, ...rest}) => {
    const [error, setError] = useState('')

    const validate = (val) => {
        setError('')
        for (let validator of validators) {
            const validatedResult = validator(val)
            if (!validatedResult.result) {
                if (check) check(true)
                return setError(validatedResult.error)
            }
        }
        if (check) check(false)
    }

    return <TextField error={Boolean(error)} helperText={error} {...rest} onChange={e => {
        onValChange(e)
        validate(e.target.value)
    }}/>
}

const validateChars = (value) => {
    const res = !!/^[a-z]+$/.test(value)

    return {
        result: res,
        error: i18n.t('errors.validateChars')
    }
}

const validateMinLength = (minLength, value) => {
    const res = value.length > minLength
    const minTextLength = minLength + 1;
    return {
        result: res,
        error: i18n.t('errors.validateMinLength', { minTextLength: minTextLength })
    }
}

const validateMaxLength = (maxLength, value) => {
    const res = value.length < maxLength
    return {
        result: res,
        // error: `Legfeljebb ${maxLength} karakter hosszú lehet`
        error: i18n.t('errors.validateMaxLength', {maxTextLength: maxLength})
    }
}

const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const res = emailRegex.test(value);
    return {
        result: res,
        error: i18n.t('errors.validateEmail')
    }
}

const validateRequired = (value) => {
    return {
        result: Boolean(value),
        error: i18n.t('errors.validateRequired')
    }
}

const validateEqualsPassword = (equal, value) => {
    return {
        result: equal === value,
        error: i18n.t('errors.validateEqualsPassword')
    }
}

const validatePassword = (value) => {
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    const isValid = hasNumber.test(value) && hasSpecialChar.test(value)
    return {
        result: isValid,
        error: i18n.t('errors.validatePassword')
    }
}

const validateVatNumber = value => {
    const vatFormat = /^(\d{8})-(\d)-(\d{2})$/
    const res = vatFormat.test(value)
    return {
        result: res,
        error: i18n.t('errors.validateVatNumber')
    }
}

export default ErrorHandlingTextField
export {validateChars, validateMinLength, validateEmail, validateRequired, validateEqualsPassword, validatePassword, validateMaxLength, validateVatNumber}