import { Route, Redirect } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRoute = ({ children, requiredPermissions=[], ...rest }) => {
    const { user, permissions } = useContext(AuthContext);
    const hasAllPermissions = () => requiredPermissions.every(item => permissions.includes(item))
    return <Route {...rest}>{!user ? <Redirect to="/login" /> : !hasAllPermissions() ? <Redirect to="/" /> : children}</Route>;
};

export default PrivateRoute;