import Typography from "@mui/material/Typography";
import {Stack, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {useContext, useState} from "react";
import AuthContext from "../../context/AuthContext";
import ErrorHandlingTextField, {validateEmail} from "../../components/ErrorHandlingTextField";
import Base from "../../components/Base";
import {useTranslation} from "react-i18next";
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';


const ForgotPassword = () => {
    const theme = useTheme()
    const {forgotPassword} = useContext(AuthContext)
    const [email, setEmail] = useState('')
    const [error, setError] = useState(false)
    const {t} = useTranslation()

    const isFilled = () => Boolean(email)
    const isError = () => !error
    const buttonText = () => {
        if (!isFilled()) return t('auth.not_filled')
        if (!isError()) return t('auth.errors')
        return t('general.send')
    }

    return <Base>
        <div className="login-container">
            <div className="login-card">
                <form>
                    <Stack spacing={{xs: 2}}>
                        <Typography variant="headline6" color={theme.palette.primary[600]}>{t('auth.forgot_password')}</Typography>
                        <Stack spacing={1} marginBottom={2} marginTop={2}>
                            <Typography variant="caption" color={theme.palette.primary[500]}>{t('auth.email')}</Typography>
                            <ErrorHandlingTextField check={(e) => setError(e)} validators={[validateEmail]} value={email} onValChange={e => setEmail(e.target.value)} name="email" variant='standard'/>
                        </Stack>
                        <Button variant="contained" endIcon={<EmailRoundedIcon/>} disabled={!isFilled()||!isError()} onClick={() => forgotPassword({email: email})}>{buttonText()}</Button>
                        <Link to="/login" style={{color: theme.palette.primary[600]}}>{t('auth.login')}</Link>
                    </Stack>
                </form>
            </div>
        </div>
    </Base>
}

export default ForgotPassword