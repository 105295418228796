import React from "react";
import TgsAppBar from "./TgsAppBar";
import TgsFooter from "./TgsFooter";


const Base = ({children}) => {

    return <>
        <TgsAppBar/>
        {children}
        <TgsFooter/>
    </>
}

export default Base