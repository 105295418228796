import './App.scss';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import tgsTheme from "./components/themes/tgsTheme";

import NotFound from "./pages/404";
import Login from "./pages/authentication/Login";
import {AuthProvider} from "./context/AuthContext";
import {ConfigProvider} from "./context/ConfigContext";
import Registration from "./pages/authentication/Registration";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import NewPassword from "./pages/authentication/NewPassword";
import Orders from "./pages/orders/Orders";
import PrivateRoute from "./utils/PrivateRoute";
import Invoices from "./pages/invoices/Invoices";
import NewOrder from "./pages/orders/NewOrder";
import {OrderProvider} from "./context/OrderContext";
import {TourOfficeProvider} from "./context/TourOfficeContext";
import SuccessfulRegistration from "./pages/authentication/SuccessfulRegistration";
import SuccessfulOrder from "./pages/orders/orderFlow/SuccessfulOrder";
import TourGuides from "./pages/tourGuides/TourGuides";
import {TourGuideProvider} from "./context/TourGuideContext";
import SuccessfulActivation from "./pages/authentication/SuccessfulActivation";


function App() {

    return <ThemeProvider theme={tgsTheme}>
        <ConfigProvider>
            <Router>
                <AuthProvider>
                    <Switch>

                        <Route path='/login' component={Login} exact/>
                        <Route path='/success' component={SuccessfulRegistration} exact/>
                        <Route path='/successful-activation' component={SuccessfulActivation} exact/>
                        <Route path='/register' component={Registration} exact/>
                        <Route path="/forgot-password" component={ForgotPassword} exact/>
                        <Route path="/new-password/:slug" component={NewPassword} exact/>


                        <TourOfficeProvider>
                            <TourGuideProvider>
                                <OrderProvider>
                                    <Switch>
                                        <PrivateRoute path="/" manager component={Orders} exact/>
                                        <PrivateRoute path="/new-order" manager component={NewOrder} exact/>
                                        <PrivateRoute path="/invoices" manager component={Invoices} exact/>
                                        <PrivateRoute path="/tour-guides" manager component={TourGuides} exact/>
                                        <PrivateRoute path="/successful-order" manager component={SuccessfulOrder} exact/>
                                        <Route component={NotFound}/>
                                    </Switch>
                                </OrderProvider>
                            </TourGuideProvider>
                        </TourOfficeProvider>
                        <Route component={NotFound}/>

                    </Switch>
                </AuthProvider>
            </Router>
        </ConfigProvider>
    </ThemeProvider>
}

export default App;