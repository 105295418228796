import {Avatar, Box, Button, IconButton, Stack, TextField} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useContext, useEffect, useState} from "react";
import OrderContext from "../../../context/OrderContext";
import useCallDataApi from "../../../hooks/data";
import Loading from "../../../components/Loading";
import ImageCard from "../../../ImageCard";
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {useTranslation} from "react-i18next";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";

const Device = ({get, set, device}) => {
    return <Stack spacing={2} direction='row' alignItems='center'>
        <Avatar src={device?.image} sx={{width: 70, height: 70, alignSelf: 'center'}}/>
        <Stack justifyContent='center'>
            <Typography align="left" variant="subtitle1">{device.name}</Typography>
            <Typography align="left" variant="body2">{device.description}</Typography>
        </Stack>
        <IconButton onClick={() => set(get() - 1)}>
            <RemoveRoundedIcon/>
        </IconButton>

        <TextField variant='standard' value={get()} onChange={e => set(e.target.value)} sx={{maxWidth: '100px'}}/>

        <IconButton onClick={() => set(get() + 1)}>
            <AddRoundedIcon/>
        </IconButton>
    </Stack>
}

const SelectDevice = () => {
    const {nextStep, getOrderRx, setOrderRx, getOrderTx, setOrderTx} = useContext(OrderContext)
    const {t} = useTranslation()
    return <Box className='white-card'>
        <Typography variant="headline4">{t('new_order.devices')}</Typography>
        <Stack spacing={2} marginTop={2} marginBottom={2}>
            <Device get={getOrderRx} set={setOrderRx} device={{image: 'https://www.dnd.hu/uploads/termek_pic/Motorola_Talkabout_T92_1.jpg', name: 'Vevő / Receiver', description: 'Vevő készülék / Receiver device'}}/>
            <Device get={getOrderTx} set={setOrderTx} device={{image: 'https://www.dnd.hu/uploads/termek_pic/Motorola_Talkabout_T92_1.jpg', name: 'Adó / Transmitter', description: 'Adó készülék / Transmitter device'}}/>
        </Stack>
      <Button endIcon={<NavigateNextRoundedIcon/>} variant='outlined' onClick={nextStep}>{t('general.next')}</Button>
    </Box>
}

export default SelectDevice