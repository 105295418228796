import AuthBase from "../../components/AuthenticatedBase";
import {useContext} from "react";
import TgsStepper from "../../components/Stepper";
import OrderContext from "../../context/OrderContext";
import SelectDeliveryAddress from "./orderFlow/SelectDeliveryAddress";
import SelectDates from "./orderFlow/SelectDates";
import SelectDevice from "./orderFlow/SelectDevice";
import SelectReturnAddress from "./orderFlow/SelectReturnAddress";
import SelectInvoiceData from "./orderFlow/SelectInvoiceData";
import Summary from "./orderFlow/Summary";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";


const NewOrder = () => {
    const {stepNumber} = useContext(OrderContext)
    const {t} = useTranslation()

    const stepMap = [
        <SelectDates/>,
        <SelectDeliveryAddress/>,
        <SelectReturnAddress/>,
        <SelectDevice/>,
        <SelectInvoiceData/>,
        <Summary/>
    ]

    return <AuthBase label={t('orders.new')}>
        <Box className="main" marginTop={6}>
            <TgsStepper/>
            {stepMap[stepNumber]}
        </Box>
    </AuthBase>
}

export default NewOrder