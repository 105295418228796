import AuthBase from "../../../components/AuthenticatedBase";
import Loading from "../../../components/Loading";
import {useEffect, useState} from "react";
import {Button, Stack, Typography, useTheme} from "@mui/material";
import happy from "../../../assets/celebrating.png";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import confettiiii from "../../../utils/confettiiiiiiiiii";
import {formatHuman, truncateString} from "../../../utils/utils";
import {useContext} from "react";
import OrderContext from "../../../context/OrderContext";
import TourOfficeContext from "../../../context/TourOfficeContext";
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';

const SuccessfulOrder = () => {
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const {isUrgent, newOrder, displayDeliveryAddress, displayReturnAddress, applyDiscount, calculatePrice, getDiscount, deleteDiscount, getDeliveryAddress, getReturnAddress, getStartDate, getEndDate, getOrderRx, getOrderTx, getTourOffice, getCode, setCode, validateCouponCode, placeOrder} = useContext(OrderContext)
    const {findTourOffice} = useContext(TourOfficeContext)
    const {t} = useTranslation()

    const theme = useTheme()

    useEffect(() => {
        confettiiii()
    }, [])

    return <AuthBase label={t('orders.success')}>
        <div className="main">
            <Stack className='white-card' spacing={2} alignItems='center'>
                <img src={happy} alt="successful registration" width='100px'/>
                <Typography align='justify' variant='headline6'>{t('orders.successfulOrder')}</Typography>
                <Stack spacing={2} marginTop={2} marginBottom={1} sx={{width: '100%'}}>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='caption' color={theme.palette.primary[500]}>{t('new_order.delivery_address')}</Typography>
                        <Typography variant='body1'>{(getDeliveryAddress()?.address && displayDeliveryAddress()) || 'Tourguide iroda'}</Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='caption' color={theme.palette.primary[500]}>{t('new_order.return_address')}</Typography>
                        <Typography variant='body1'>{(getReturnAddress()?.address && displayReturnAddress()) || 'Tourguide iroda'}</Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='caption' color={theme.palette.primary[500]}>{t('new_order.order_dates')}</Typography>
                        <Typography variant='body1'>{getStartDate() ? formatHuman(getStartDate()) : '-'} - {getEndDate() ? formatHuman(getEndDate()) : '-'}</Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='caption' color={theme.palette.primary[500]}>{t('new_order.ordered_devices')}</Typography>
                        <Typography variant='body1'>{t('new_order.receiver')}: {getOrderRx()} | {t('new_order.transmitter')}: {getOrderTx()}</Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='caption' color={theme.palette.primary[500]}>{t('new_order.invoice_data')}</Typography>
                        <Typography variant='body1'>{truncateString(findTourOffice(getTourOffice())?.name, 100)}</Typography>
                    </Stack>
                    <Stack direction='row' justifyContent='space-between'>
                        <Typography variant='caption' color={theme.palette.primary[500]}>{t('general.price')}</Typography>
                        {getDiscount() ? <Stack direction='row' spacing={1}><Typography variant='body1Overline'>{calculatePrice()} Ft</Typography><Typography variant='body1'>{applyDiscount(calculatePrice())} Ft</Typography></Stack> : <Typography variant='body1'>{calculatePrice()} Ft</Typography>}
                    </Stack>
                </Stack>
                <Button variant='contained' endIcon={<HomeRoundedIcon/>} onClick={() => history.push('/')}>{t('auth.goHome')}</Button>
                <Button variant='contained' endIcon={<ReplayRoundedIcon/>} onClick={() => {
                    newOrder()
                    history.push('/new-order')
                }}>{t('orders.new')}</Button>
            </Stack>
        </div>
        <Loading isLoading={loading}/>
    </AuthBase>
}

export default SuccessfulOrder