import {Autocomplete, Box, Stack, TextField, Typography, useTheme} from "@mui/material";
import React, {useState} from "react";
import CustomTextField, {validateRequired, validateVatNumber} from "../../components/CustomTextFiled";
import ErrorHandlingForm from "../../components/ErrorHandlingForm";
import {useTranslation} from "react-i18next";
import {countries} from "../../utils/countries";
import ErrorHandlingCheckbox from "../../components/ErrorHandlingCheckbox";

const InvoiceDataData = ({obj, reference}) => {
    const theme = useTheme()
    const {t} = useTranslation()
    const [formData, setFormData] = useState({
        name: obj?.name || '',
        short_name: obj?.short_name || '',
        vat_number: obj?.vat_number || '',
        country: obj?.country || '',
        zip_code: obj?.zip_code || '',
        city: obj?.city || '',
        address: obj?.address || '',
        is_company: obj?.is_company || true,
    })

    const [errors, setErrors] = useState({
        name: false,
        short_name: false,
        vat_number: false,
        country: false,
        zip_code: false,
        city: false,
        address: false,
        is_company: false,
    })

    const validators = {
        name: [validateRequired],
        short_name: [validateRequired],
        vat_number: [validateRequired, validateVatNumber],
        country: [validateRequired],
        zip_code: [validateRequired],
        city: [validateRequired],
        address: [validateRequired],
        is_company: [],
    };

    return <ErrorHandlingForm reference={reference} errors={errors} setErrors={setErrors} data={formData} setData={setFormData} validators={validators} style={{marginTop: '15px'}}>
        <Stack spacing={1}>
            <Typography variant='caption' color={theme.palette.primary[500]}>{t('invoice.name')}*</Typography>
            <CustomTextField
                placeholder={t('invoice.name')}
                name="name"
                variant="standard"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />

            <Typography variant='caption' color={theme.palette.primary[500]}>{t('invoice.short_name')}*</Typography>
            <CustomTextField
                placeholder={t('invoice.short_name')}
                name="short_name"
                variant="standard"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />

            <Typography variant='caption' color={theme.palette.primary[500]}>{t('invoice.vat_number')}*</Typography>
            <CustomTextField
                placeholder={t('invoice.vat_number')}
                name="vat_number"
                variant="standard"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
            <Stack direction='row' spacing={1}>
                <Stack spacing={1} sx={{width: '25%'}}>
                    <Typography variant='caption' color={theme.palette.primary[500]}>{t('invoice.country')}*</Typography>
                    <Autocomplete
                        id="country-select-person"
                        sx={{width: '100%'}}
                        options={countries}
                        autoHighlight
                        value={formData?.country || null}
                        onChange={(event, newValue) => {
                            setFormData({...formData, country: newValue});
                        }}
                        isOptionEqualToValue={(option, value) => option.code === value.code}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{'& > img': {mr: 1, flexShrink: 0}}} {...props}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                />
                                {option.code}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='standard'
                                fullWidth
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </Stack>
                <Stack spacing={1} sx={{width: '25%'}}>
                    <Typography variant='caption' color={theme.palette.primary[500]}>{t('invoice.postal_code')}*</Typography>
                    <CustomTextField
                        placeholder={t('invoice.postal_code')}
                        name="zip_code"
                        variant="standard"
                        data={formData}
                        setData={setFormData}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                    />
                </Stack>
                <Stack spacing={1} sx={{width: '50%'}}>
                    <Typography variant='caption' color={theme.palette.primary[500]}>{t('invoice.city')}*</Typography>
                    <CustomTextField
                        placeholder={t('invoice.city')}
                        name="city"
                        variant="standard"
                        data={formData}
                        setData={setFormData}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                    />
                </Stack>
            </Stack>

            <Typography variant='caption' color={theme.palette.primary[500]}>{t('invoice.address')}*</Typography>
            <CustomTextField
                placeholder={t('invoice.address')}
                name="address"
                variant="standard"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
            <Typography variant='caption' color={theme.palette.primary[500]}>{t('invoice.company')}*</Typography>
            <Box>
                <ErrorHandlingCheckbox validators={[]} check={v => setErrors({...errors, is_company: v})} onValChange={e => setFormData({...formData, is_company: e.target.checked})} checked={formData?.is_company}/>
            </Box>
        </Stack>
    </ErrorHandlingForm>
}

export default InvoiceDataData