import Typography from "@mui/material/Typography";
import ErrorHandlingTextField, {validateRequired, validateVatNumber} from "../../components/ErrorHandlingTextField";
import {Stack, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";

const FromNAV = ({dataHandler, setDataHandler, errors, setErrors}) => {
    const theme = useTheme()
    const {t} = useTranslation()

    return <form>
        <Stack spacing={1}>
            <Typography variant='caption' color={theme.palette.primary[500]}>{t('invoice.vat_number')}*</Typography>
            <ErrorHandlingTextField variant='standard' check={v => setErrors({vat_number: v})} validators={[validateRequired, validateVatNumber]} onValChange={e => setDataHandler({vat_number: e.target.value})} value={dataHandler?.vat_number}/>
        </Stack>
    </form>
}

export default FromNAV