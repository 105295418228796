import Typography from "@mui/material/Typography";
import {Box, Button, Divider, Stack, TextField, useTheme} from "@mui/material";
import {useContext} from "react";
import OrderContext from "../../../context/OrderContext";
import {formatHuman, thousandSeparator} from "../../../utils/utils";
import TourOfficeContext from "../../../context/TourOfficeContext";
import {useTranslation} from "react-i18next";
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import PublishedWithChangesRoundedIcon from '@mui/icons-material/PublishedWithChangesRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

const Summary = () => {
    const {getComment, setComment, displayDeliveryAddress, displayReturnAddress, applyDiscount, calculatePrice, getDiscount, deleteDiscount, getDeliveryAddress, getReturnAddress, getStartDate, getEndDate, getOrderRx, getOrderTx, getTourOffice, getCode, setCode, validateCouponCode, placeOrder} = useContext(OrderContext)
    const {findTourOffice} = useContext(TourOfficeContext)
    const {t} = useTranslation()

    const theme = useTheme()
    return <Box className="white-card">
        <Typography variant="headline4">{t('new_order.summary')}</Typography>
        <Stack spacing={2} marginTop={2} marginBottom={1}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='caption' color={theme.palette.primary[500]}>{t('new_order.delivery_address')}</Typography>
                <Typography variant='body1'>{(getDeliveryAddress()?.address && displayDeliveryAddress()) || 'Tourguide iroda'}</Typography>
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='caption' color={theme.palette.primary[500]}>{t('new_order.return_address')}</Typography>
                <Typography variant='body1'>{(getReturnAddress()?.address && displayReturnAddress()) || 'Tourguide iroda'}</Typography>
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='caption' color={theme.palette.primary[500]}>{t('new_order.order_dates')}</Typography>
                <Typography variant='body1'>{getStartDate() ? formatHuman(getStartDate()) : '-'} - {getEndDate() ? formatHuman(getEndDate()) : '-'}</Typography>
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='caption' color={theme.palette.primary[500]}>{t('new_order.ordered_devices')}</Typography>
                <Typography variant='body1'>{t('new_order.receiver')}: {getOrderRx()} | {t('new_order.transmitter')}: {getOrderTx()}</Typography>
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='caption' color={theme.palette.primary[500]}>{t('new_order.invoice_data')}</Typography>
                <Typography variant='body1'>{findTourOffice(getTourOffice())?.name}</Typography>
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant='caption' color={theme.palette.primary[500]}>{t('general.price')}</Typography>
                {getDiscount() ? <Stack direction='row' spacing={1}><Typography variant='body1Overline'>{thousandSeparator(calculatePrice())} Ft</Typography><Typography variant='body1'>{thousandSeparator(applyDiscount(calculatePrice()))} Ft</Typography></Stack> : <Typography variant='body1'>{thousandSeparator(calculatePrice())} Ft</Typography>}
            </Stack>
            <Stack spacing={2}>
                <Typography variant='caption' color={theme.palette.primary[500]}>{t('general.comment')}</Typography>
                <TextField placeholder={t('general.comment_description')} variant='standard' multiline rows={3} value={getComment()} onChange={e => setComment(e.target.value)}/>
            </Stack>

        </Stack>
        <Divider/>
        <Stack marginTop={2} spacing={2}>
            <Typography variant='caption' color={theme.palette.primary[500]}>{t('new_order.coupon_code')}</Typography>
            {!getDiscount() ? <Stack spacing={2} direction='row' alignItems='center'>
                <TextField variant='standard' value={getCode()} onChange={e => setCode(e.target.value)}/>
                <Button variant='outlined' endIcon={<PublishedWithChangesRoundedIcon/>} onClick={validateCouponCode}>{t('new_order.validate')}</Button>
            </Stack> : <Stack spacing={2} direction='row' alignItems='center'>
                <Typography variant='body1'>{getDiscount()?.name} {getDiscount()?.minus ? '-' + thousandSeparator(getDiscount()?.minus) + ' Ft' : getDiscount()?.percent + '%'}</Typography>
                <Button variant='outlined' endIcon={<DeleteRoundedIcon/>} onClick={deleteDiscount}>{t('new_order.delete_code')}</Button>
            </Stack>}
        </Stack>
        <Stack marginTop={2} spacing={2}>
            <Button endIcon={<ArrowUpwardRoundedIcon/>} variant='outlined' onClick={() => placeOrder()}>{t('new_order.submit')}</Button>
        </Stack>

    </Box>
}

export default Summary