import {createTheme} from "@mui/material/styles";
import {huHU} from "@mui/material/locale";
import typography from "./typography";
import {components} from "./componentDef";


const palette = {
    '900': '#09120D',
    '800': '#182E21',
    '700': '#2F5C43',
    'A700': '#2F5C43',
    '600': '#478A64',
    '500': '#5EB885',
    '400': '#7EC69D',
    'A400': '#7EC69D',
    '300': '#9ED4B6',
    '200': '#BFE3CE',
    'A200': '#BFE3CE',
    '100': '#DFF1E7',
    'A100': '#DFF1E7',
    '50': '#EFF8F3',
    '20': '#F4FAF7'
}

const primaryColor = {
    '900': '#0A1929',
    '800': '#1C2C47',
    '700': '#253b59',
    'A700': '#283b54',
    '600': '#214154',
    '500': '#455972',
    '400': '#425b75',
    'A400': '#A0B9D7',
    '300': '#C1D2EB',
    '200': '#E2EAF5',
    'A200': '#E2EAF5',
    '100': '#F3F7FC',
    'A100': '#F3F7FC',
    '50': '#FAFDFF',
    '20': '#FEFEFF',
};

const secondaryColor = {
    '900': '#FAB42A',
    '800': '#FAC649',
    '700': '#FBCF6F',
    'A700': '#FBCF6F',
    '600': '#FCE29A',
    '500': '#FDF3BF',
    '400': '#FEF5D7',
    'A400': '#FEF5D7',
    '300': '#FEF8EB',
    '200': '#FEFAF5',
    'A200': '#FEFAF5',
    '100': '#FEFDFC',
    'A100': '#FEFDFC',
    '50': '#FEFEFD',
    '20': '#FEFEFE',
};



const tgsTheme = createTheme({
        components: components,
        name: 'tgs',
        palette: {
            primary: primaryColor,
            secondary: secondaryColor,
        },
        typography: typography
    },
    huHU
)

export default tgsTheme