import Typography from "@mui/material/Typography";
import {InputAdornment, Stack, TextField, useTheme} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import Button from "@mui/material/Button";
import {useContext, useState} from "react";
import AuthContext from "../../context/AuthContext";
import IconButton from "@mui/material/IconButton";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import Base from "../../components/Base";
import {useTranslation} from "react-i18next";


const NewPassword = () => {
    const theme = useTheme()
    const {newPassword} = useContext(AuthContext);
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [data, setData] = useState({
        password: '',
        password_again: ''
    })
    const {slug} = useParams()
    const {t} = useTranslation()

    return <Base>
        <div className="login-container">
            <div className="login-card">
                <form>
                    <Stack spacing={{xs: 2}}>
                        <Typography variant="headline6" color={theme.palette.primary[600]}>{t('auth.changePassword')}</Typography>
                        <Stack spacing={1} marginBottom={2} marginTop={2}>
                            <input type="hidden" name='slug' value={slug}/>
                            <Typography variant="caption" color={theme.palette.primary[500]}>{t('auth.newPassword')}</Typography>
                            <TextField name="password" type={passwordVisible ? "text" : "password"} variant='standard' InputProps={{
                                endAdornment: <InputAdornment position="end"><IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                    edge="end"
                                >
                                    {passwordVisible ? <VisibilityOffRoundedIcon /> : <RemoveRedEyeRoundedIcon />}
                                </IconButton>
                                </InputAdornment>
                            }}/>
                            <Typography variant="caption" color={theme.palette.primary[500]}>{t('auth.newPasswordAgain')}</Typography>
                            <TextField name="password_again" type={passwordVisible ? "text" : "password"} variant='standard' InputProps={{
                                endAdornment: <InputAdornment position="end"><IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                    edge="end"
                                >
                                    {passwordVisible ? <VisibilityOffRoundedIcon /> : <RemoveRedEyeRoundedIcon />}
                                </IconButton>
                                </InputAdornment>
                            }}/>
                        </Stack>
                        <Button onClick={() => newPassword(data)} variant="contained" type="submit">{t('auth.changePassword')}</Button>
                    </Stack>
                </form>
            </div>
        </div>
    </Base>
}

export default NewPassword