import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AuthBase from "../../components/AuthenticatedBase";
import {useTranslation} from "react-i18next";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import TgsDataGrid from "../../components/DataGrid";
import {useContext} from "react";
import TourGuideContext from "../../context/TourGuideContext";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

const TourGuides = () => {
    const {t} = useTranslation()
    const {
        tourGuides,
        newTourGuideOpen,
        editTourGuideOpen,
        deleteTourGuideOpen,
        setCurrentTourGuide
    } = useContext(TourGuideContext)


    const columns = [
        {
            field: "manage",
            headerName: t('general.manage'),
            sortable: false,
            type: "actions",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditRoundedIcon/>}
                    label={t('general.edit')}
                    onClick={() => {
                        setCurrentTourGuide(params.row.id)
                        editTourGuideOpen(true)
                    }}
                    showInMenu={true}/>,
                <GridActionsCellItem
                    icon={<DeleteRoundedIcon/>}
                    label={t('general.delete')}
                    onClick={() => {
                        setCurrentTourGuide(params.row.id)
                        deleteTourGuideOpen(true)
                    }}
                    showInMenu={true}/>
            ]
        },
        {field: "first_name", headerName: t('tour_guides.first_name'), width: 140},
        {field: "last_name", headerName: t('tour_guides.last_name'), width: 130},
        {field: "phone", headerName: t('tour_guides.phone'), width: 130},
        {field: "email", headerName: t('tour_guides.email'), width: 200},
    ]


    const buttons = [{
        name: t('tour_guides.new'),
        props: {
            variant: 'contained',
            size: 'large',
            endIcon: <AddCircleOutlineRoundedIcon/>,
            onClick: () => newTourGuideOpen(true)
        }
    }]

    const filterButtons = [
        {
            name: t('tour_guides.new'),
            props: {
                variant: 'contained',
                size: 'small',
                endIcon: <AddCircleOutlineRoundedIcon/>,
                onClick: () => newTourGuideOpen(true)
            }
        }
    ]

    return <AuthBase label={t('tour_guides.title')} buttons={buttons} filterButtonsMobile={filterButtons}>
        <div className="main">
            <TgsDataGrid rows={tourGuides} columns={columns}/>
        </div>
    </AuthBase>
}

export default TourGuides