import {format} from "date-fns";

const formatHuman = (date) => format(date, "yyyy-MM-dd")

const thousandSeparator = (num) => {
    if (num === undefined) return num
    if (num === null) return num
    if (num === '') return num
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const daysBetween = (date1, date2) => {
    const differenceMs = Math.abs(date2 - date1)
    // Convert milliseconds to days
    return Math.ceil(differenceMs / (1000 * 60 * 60 * 24))
}

const truncateString = (str, length) => {
    return str?.length <= length ? str : str?.slice(0, length) + "...";
}

const cleanData = data => {
    let object = {};

    Object.keys(data).forEach(key => {
        const value = data[key]
        console.log(`getFormData -> forEach -> key: ${key} & value: ${value}`)

        if (key.startsWith('logicalfield-')) {
            const newKey = key.replace('logicalfield-', '')

            console.log(`getFormData -> forEach logicalfield  ->newKey: ${newKey} & value: ${value}`)

            object[newKey] = value === 'true'
        } else if (key.startsWith('nullable-')) {
            const newKey = key.replace('nullable-', '')

            console.log(`getFormData -> forEach nullable  ->newKey: ${newKey} & value: ${value}`)

            object[newKey] = value === '' ? null : value
        } else {
            if (value !== "") {

                console.log(`getFormData -> forEach else not empty value -> key: ${key} & value: ${value}`)

                object[key] = value
            } else {
                console.log('EMPTY!!!!')
            }
        }
        console.log('------------------------------------------------------')
    });
    return object
}

export {formatHuman, thousandSeparator, daysBetween, truncateString, cleanData}