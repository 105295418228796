import {Box, Button, FormControl, MenuItem, Select, Stack} from "@mui/material";
import {useContext, useEffect} from "react";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LicenseInfo} from "@mui/x-data-grid-pro";
import {hu, enGB} from "date-fns/locale";
import OrderContext from "../../../context/OrderContext";
import {formatHuman} from "../../../utils/utils";
import {useTranslation} from "react-i18next";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import {DateRangePicker} from "@mui/x-date-pickers-pro";


const SelectDates = () => {
    const {
        nextStep, getStartDate, getEndDate, setDates, getAvailability, availability, getDeliveryTime,
        getReturnTime,
        setDeliveryTime,
        setReturnTime
    } = useContext(OrderContext)
    const {t} = useTranslation()
    LicenseInfo.setLicenseKey(
        process.env.REACT_APP_MUI,
    );

    const getNextMonthLastDay = date => {
        const currentDate = new Date(date)
        const currentMonth = currentDate.getMonth()
        const currentYear = currentDate.getFullYear()
        let nextMonth = currentMonth + 2
        let nextYear = currentYear
        if (nextMonth === 12) {
            nextMonth = 0
            nextYear++
        }
        const firstDayOfNextMonth = new Date(nextYear, nextMonth, 1)
        return new Date(firstDayOfNextMonth.getTime() - 1)
    }

    const getOneYearLater = () => {
        const currentDate = new Date()
        const nextYear = currentDate.getFullYear() + 1
        currentDate.setFullYear(nextYear)
        return formatHuman(currentDate)
    }

    const generateTimeList = () => {
        const timeList = [];
        for (let hour = 9; hour < 17; hour++) {
            for (let minute = 0; minute < 60; minute += 15) {
                const hourString = hour.toString().padStart(2, '0'); // Formatting the hour
                const minuteString = minute.toString().padStart(2, '0'); // Formatting the minute
                const timeString = hourString + ':' + minuteString; // Combine hour and minute
                timeList.push(timeString);
            }
        }
        return timeList;
    }

    useEffect(() => {
        const firstDay = new Date().setDate(1)
        getAvailability(firstDay, getNextMonthLastDay(firstDay))
    }, [])

    const localizedText = {
        startText: t('picker.startText'),
        endText: t('picker.endText'),
        startTextOpen: t('picker.startTextOpen'),
        endTextOpen: t('picker.endTextOpen'),
        clearText: t('picker.clearText'),
        applyText: t('picker.applyText'),
    };

    return <Box className="white-card">
        <Typography variant="headline4">{t('new_order.order_dates')}</Typography>
        {/*<Typography variant="body2">{t('new_order.selected_dates')}: {getStartDate() ? formatHuman(getStartDate()) : '-'} - {getEndDate() ? formatHuman(getEndDate()) : '-'}</Typography>*/}
        <Stack justifyContent='center' alignItems='center' marginTop={2} marginBottom={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
                <DateRangePicker
                    displayStaticWrapperAs="desktop"
                    label={null}
                    value={[getStartDate(), getEndDate()]}
                    startText={localizedText.startText}
                    endText={localizedText.endText}
                    startTextOpen={localizedText.startTextOpen}
                    endTextOpen={localizedText.endTextOpen}
                    clearText={localizedText.clearText}
                    applyText={localizedText.applyText}
                    disablePast
                    maxDate={getOneYearLater()}
                    shouldDisableDate={d => availability[formatHuman(d)]}
                    onMonthChange={firstDayOfMonth => getAvailability(firstDayOfMonth, getNextMonthLastDay(firstDayOfMonth))}
                    onChange={newValue => setDates(newValue)}
                    renderInput={(startProps, endProps) => (
                        <>
                            <Stack spacing={1}>
                                <Typography>{t('new_order.start')}</Typography>
                                <TextField variant='standard' disabled={startProps?.disabled} error={startProps?.error} inputProps={startProps?.inputProps} ref={startProps?.ref} focused={startProps?.focused} onClick={startProps?.onClick} />
                            </Stack>
                            <Box sx={{mx: 2}}> - </Box>
                            <Stack spacing={1}>
                                <Typography>{t('new_order.end')}</Typography>
                                <TextField variant='standard' disabled={endProps?.disabled} error={endProps?.error} inputProps={endProps?.inputProps} ref={endProps?.ref} focused={endProps?.focused} onClick={endProps?.onClick} />
                            </Stack>
                        </>
                    )}
                />
            </LocalizationProvider>
        </Stack>
        <Stack direction='row' spacing={2} marginBottom={2} justifyContent='center' alignItems='center'>
            <FormControl>
                <Stack spacing={1}>
                    <Typography>{t('new_order.start_time')}</Typography>
                    <Select
                        id="start-time-select"
                        variant='standard'
                        value={getDeliveryTime()}
                        onChange={e => setDeliveryTime(e.target.value)}
                    >
                        <MenuItem value='EN'>{t('new_order.en')}</MenuItem>
                        <MenuItem value='DE'>{t('new_order.de')}</MenuItem>
                        <MenuItem value='DU'>{t('new_order.du')}</MenuItem>
                        {generateTimeList().map(t => <MenuItem key={`start_${t}`} value={t}>{t}</MenuItem>)}
                    </Select>
                </Stack>
            </FormControl>
            <Box sx={{mx: 2}}> - </Box>
            <FormControl>
                <Stack spacing={1}>
                    <Typography>{t('new_order.end_time')}</Typography>
                    <Select
                        id="end-time-select"
                        variant='standard'
                        value={getReturnTime()}
                        onChange={e => setReturnTime(e.target.value)}
                    >
                        <MenuItem value='EN'>{t('new_order.en')}</MenuItem>
                        <MenuItem value='DE'>{t('new_order.de')}</MenuItem>
                        <MenuItem value='DU'>{t('new_order.du')}</MenuItem>
                        {generateTimeList().map(t => <MenuItem key={`end_${t}`} value={t}>{t}</MenuItem>)}
                    </Select>
                </Stack>
            </FormControl>
        </Stack>
        <Button onClick={nextStep} endIcon={<NavigateNextRoundedIcon/>} disabled={!(getStartDate() && getEndDate() && getDeliveryTime() && getReturnTime())}
                variant='outlined'>{t('general.next')}</Button>
    </Box>
}

export default SelectDates