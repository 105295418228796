import {createContext, useContext, useEffect, useState, useRef} from "react";
import useCallDataApi from "../hooks/data";
import TgsDialog from "../components/Dialog";
import InvoiceDataData from "../pages/invoices/invoiceDataData";
import Loading from "../components/Loading";
import {Button} from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import AuthContext from "./AuthContext";
import {useTranslation} from "react-i18next";
import FromNAV from "../pages/invoices/FromNAV";
import {enqueueSnackbar} from "notistack";
import {cleanData} from "../utils/utils";

const TourOfficeContext = createContext();

export default TourOfficeContext;

export const TourOfficeProvider = ({children}) => {
    const {user} = useContext(AuthContext)
    const {t} = useTranslation()
    const {
        getData: getTourOffices,
        postData: postTourOffice,
        updateData: updateTourOffice,
        deleteData: deleteTourOffice
    } = useCallDataApi('core/client-tour-offices')
    const [current, setCurrent] = useState(-1)
    const [tourOffices, setTourOffices] = useState([])
    const [newOpen, setNewOpen] = useState(false)
    const [newFromNavOpen, setNewFromNavOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [dataNavHandler, setDataNavHandler] = useState({vat_number: ''})
    const [navErrors, setNavErrors] = useState({vat_number: false})
    const [loading, setLoading] = useState(true)
    const invoiceDataForm = useRef(null)

    useEffect(() => {
        if (user) getTourOffices().then(d => setTourOffices(d)).finally(() => setLoading(false))
        else setLoading(false)
    }, [user])

    const findTourOffice = id => tourOffices?.find(t => t.id === id)

    const newTourOffice = data => {
        setLoading(true)
        postTourOffice('', data).then(d => setTourOffices([...tourOffices, d])).finally(() => {
            setLoading(false)
        })
    }

    const newTourOfficeFromNav = () => {
        setLoading(true)
        postTourOffice('from_nav/', dataNavHandler)
            .then(d => setTourOffices([...tourOffices, d]))
            .catch(e => {
                setLoading(false)
                enqueueSnackbar(t('errors.unexpected'), {variant: 'error'})
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const removeTourOffice = () => {
        setLoading(true)
        deleteTourOffice(current).then(() => setTourOffices([...tourOffices.filter(d => d?.id !== current)])).finally(() => setLoading(false))
    }

    const editTourOffice = data => {
        setLoading(true)
        updateTourOffice(`${current}/`, data).then(d => {
            const indexOfCurrent = tourOffices.findIndex(t => t.id === d?.id)
            tourOffices[indexOfCurrent] = d
            setTourOffices([...tourOffices])
            setEditOpen(false)
        }).finally(() => setLoading(false))
    }

    const isNavFilled = () => Object.values(dataNavHandler || [])?.every(x => x !== '')
    const isNavError = () => Object.values(navErrors || [])?.every(d => d === false)

    const contextData = {
        newTourOfficeOpen: setNewOpen,
        setNewFromNavOpen: setNewFromNavOpen,
        editTourOfficeOpen: setEditOpen,
        deleteTourOfficeOpen: setDeleteOpen,
        setCurrentTourOffice: setCurrent,
        findTourOffice,
        tourOffices,
    }

    const newActions = <>
        <Button variant='outlined' style={{padding: 15}}
                onClick={() => setNewOpen(false)}>{t('general.cancel')}</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            const rawData = invoiceDataForm.current.getData()
            if (rawData) {
                const data = cleanData(rawData)
                newTourOffice(data)
                setNewOpen(false)
            }
        }} endIcon={<AddCircleOutlineRoundedIcon/>}>{t('general.add')}</Button>
    </>

    const newFromNavActions = <>
        <Button variant='outlined' style={{padding: 15}}
                onClick={() => setNewFromNavOpen(false)}>{t('general.cancel')}</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setNewFromNavOpen(false)
            newTourOfficeFromNav()
        }} endIcon={<AddCircleOutlineRoundedIcon/>} disabled={!isNavFilled() || !isNavError()}>{t('general.add')}</Button>
    </>

    const deleteActions = <>
        <Button variant='outlined' style={{padding: 15}}
                onClick={() => setDeleteOpen(false)}>{t('general.cancel')}</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            setDeleteOpen(false)
            removeTourOffice()
        }} endIcon={<DeleteRoundedIcon/>}>{t('general.delete')}</Button>
    </>

    const editActions = <>
        <Button variant='outlined' style={{padding: 15}}
                onClick={() => setEditOpen(false)}>{t('general.cancel')}</Button>
        <Button variant='contained' style={{padding: 15}} onClick={() => {
            const rawData = invoiceDataForm.current.getData()
            if (rawData) {
                const data = cleanData(rawData)
                editTourOffice(data)
                setEditOpen(false)
            }
        }} endIcon={<SaveRoundedIcon/>}>{t('general.save')}</Button>
    </>

    return <TourOfficeContext.Provider value={contextData}>
        {children}
        <TgsDialog open={editOpen}
                   title={t('invoice.edit_title')}
                   subtitle={t('invoice.edit_subtitle')}
                   actions={editActions}
                   handleClose={() => setEditOpen(false)}>
            <InvoiceDataData reference={invoiceDataForm} obj={findTourOffice(current)}/>
        </TgsDialog>

        <TgsDialog open={newOpen}
                   title={t('invoice.new_title')}
                   subtitle={t('invoice.new_subtitle')}
                   actions={newActions}
                   handleClose={() => setNewOpen(false)}>
            <InvoiceDataData reference={invoiceDataForm}/>
        </TgsDialog>

        <TgsDialog open={newFromNavOpen}
                   title={t('invoice.new_title')}
                   subtitle={t('invoice.new_subtitle')}
                   actions={newFromNavActions}
                   handleClose={() => setNewFromNavOpen(false)}>
            <FromNAV dataHandler={dataNavHandler} setDataHandler={setDataNavHandler} errors={navErrors}
                     setErrors={setNavErrors}/>
        </TgsDialog>

        <TgsDialog open={deleteOpen}
                   title={t('invoice.delete_title')}
                   subtitle={t('invoice.delete_subtitle')}
                   actions={deleteActions}
                   handleClose={() => setDeleteOpen(false)}/>

        <Loading isLoading={loading}/>
    </TourOfficeContext.Provider>
}