import {TextField} from "@mui/material";
import i18n from "../i18n";


const CustomTextField = ({errors, setErrors, data, setData, name, validators, ...rest}) => {

    const handleChange = (event) => {
        const {name, value} = event.target;
        setData({...data, [name]: value});
    };

    const handleBlur = (event) => {
        const {name, value} = event.target;
        for (let validator of validators[name]) {
            const {valid, error} = validator(value)
            if (!valid) {
                setErrors({...errors, [name]: error})
                break
            } else setErrors({...errors, [name]: ''})
        }
    };

    return <TextField
        name={name}
        value={data[name]}
        onChange={e => {
            handleBlur(e)
            handleChange(e)
        }}
        onBlur={handleBlur}
        error={Boolean(errors[name])}
        helperText={errors[name] && errors[name]}
        {...rest}
    />
}

const validateChars = (value) => {
    const res = !!/^[a-z]+$/.test(value)
    return {
        valid: res,
        error: 'Csak ékezet nélküli kisbetűs karaktereket tartalmazhat (a-z)'
    }
}

const validateMinLength = (minLength, value) => {
    const res = value.length > minLength
    const minTextLength = minLength + 1
    return {
        valid: res,
        error: i18n.t('errors.validateMinLength', { minTextLength: minTextLength })
    }
}

const validateMaxLength = (maxLength, value) => {
    const res = value.length < maxLength
    return {
        valid: res,
        error: i18n.t('errors.validateMaxLength', {maxTextLength: maxLength})
    }
}

const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const res = emailRegex.test(value);
    return {
        valid: res,
        error: i18n.t('errors.validateEmail')
    }
}

const validateVatNumber = (value) => {
    const emailRegex = /^\d{8}-\d-\d{2}$/;
    const res = emailRegex.test(value);
    return {
        valid: res,
        error: 'Nem érvényes adószám, (xxxxxxxx-y-zz)'
    }
}

const validateNumber = (value) => {
    const numericRegex = /^-?[0-9]+$/;
    const res = numericRegex.test(value);
    return {
        valid: res,
        error: 'Csak számokat tartalmazhat'
    }
}

const validateRequired = (value) => {
    return {
        valid: Boolean(value),
        error: i18n.t('errors.validateRequired')
    }
}

const validateEqualsPassword = (equal, value) => {
    return {
        valid: equal === value,
        error: 'Nem egyezik meg a két jelszó'
    }
}

const validatePassword = (value) => {
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    const isValid = hasNumber.test(value) && hasSpecialChar.test(value)
    return {
        valid: isValid,
        error: 'Tartalmaznia kell egy speciális karaktert (!@#$%^&*(),.?":{}|<>) és egy számot'
    }
}

export default CustomTextField
export {
    validateChars,
    validateMinLength,
    validateEmail,
    validateRequired,
    validateEqualsPassword,
    validatePassword,
    validateMaxLength,
    validateNumber,
    validateVatNumber
}