import Base from "../../components/Base";
import {Button, Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import happy from "../../assets/celebrating.png"
import {useHistory} from "react-router-dom";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import confettiiii from "../../utils/confettiiiiiiiiii";


const SuccessfulActivation = () => {
    const {t} = useTranslation()
    const history = useHistory()

    confettiiii()

    return <Base>
        <div className="login-container">
            <Stack className='white-card' spacing={2} alignItems='center'>
                <img src={happy} alt="successful activation" width='100px'/>
                <Typography align='justify' variant='headline6'>{t('auth.successfulActivation')}</Typography>
                <Button variant='contained' endIcon={<HomeRoundedIcon/>} onClick={() => history.push('/login')}>{t('auth.goHome')}</Button>
            </Stack>
        </div>
    </Base>
}

export default SuccessfulActivation