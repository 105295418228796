import AuthBase from "../../components/AuthenticatedBase";
import {useContext} from "react";
import {Stack, Typography} from "@mui/material";
import InvoiceData from "./InvoiceData";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import TourOfficeContext from "../../context/TourOfficeContext";
import {useTranslation} from "react-i18next";
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';

const Invoices = () => {
    const {tourOffices, setNewFromNavOpen, findTourOffice, setCurrentTourOffice, newTourOfficeOpen, editTourOfficeOpen, deleteTourOfficeOpen} = useContext(TourOfficeContext)
    const {t} = useTranslation()

    const newTourOffice = () => newTourOfficeOpen(true)
    const newTourOfficeFromNav = () => setNewFromNavOpen(true)

    const buttons = [{
        name: t('invoice.new'),
        props: {
            variant: 'contained',
            size: 'large',
            endIcon: <AddCircleOutlineRoundedIcon/>,
            onClick: () => newTourOffice()
        }
    },{
        name: t('invoice.newFromNav'),
        props: {
            variant: 'contained',
            size: 'large',
            endIcon: <ZoomInRoundedIcon/>,
            onClick: () => newTourOfficeFromNav()
        }
    }]

    const filterButtons = [
        {
            name: t('invoice.new'),
            props: {
                variant: 'contained',
                size: 'small',
                endIcon: <AddCircleOutlineRoundedIcon/>,
                onClick: () => newTourOffice()
            }
        },
        {
            name: t('invoice.newFromNav'),
            props: {
                variant: 'contained',
                size: 'small',
                endIcon: <ZoomInRoundedIcon/>,
                onClick: () => newTourOfficeFromNav()
            }
        }
    ]

    return <AuthBase label={t('invoice.heading')} buttons={buttons} filterButtonsMobile={filterButtons}>
        <div className="main">
            {tourOffices?.length === 0 && <Typography variant='headline6'>{t('invoice.noInvoiceDetail')}</Typography>}
            <Stack spacing={2}>
                {tourOffices?.map(d => <InvoiceData key={d?.id} {...d} deleteAction={() => {
                    setCurrentTourOffice(d?.id)
                    deleteTourOfficeOpen(true)
                }} editAction={() => {
                    setCurrentTourOffice(d?.id)
                    editTourOfficeOpen(true)
                }}/>)}
            </Stack>
        </div>
    </AuthBase>
}

export default Invoices