import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Stack,
    useTheme
} from "@mui/material";
import {useContext, useState} from "react";
import OrderContext from "../../../context/OrderContext";
import Typography from "@mui/material/Typography";
import GoogleMapsAutocomplete from "../../../components/PlacesAutocomplete2";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import Map from "../../../components/Map";
import {Marker} from "@react-google-maps/api";
import {useTranslation} from "react-i18next";

const SelectReturnAddress = () => {
    const theme = useTheme()
    const {setReturnAddress, getReturnAddress, getReturnType, setReturnType, nextStep} = useContext(OrderContext)
    const [address, setAddress] = useState(getReturnAddress())
    const {t} = useTranslation()

    const displayAddress = () => `${address?.address?.country || ''}, ${address?.address?.city || ''} ${address?.address?.postal_code || ''} ${address?.address?.street || ''} ${address?.address?.street_number || ''}`

    return <Box className="white-card2">
        <Stack direction={{xs: 'column', md: 'row'}}>
            <Stack justifyContent="space-between" sx={{width: {xs: "100%", md: "90%"}, padding: "20px"}}>
                <Stack spacing={1}>
                    <Typography variant="headline4">{t('new_order.return_address')}</Typography>
                    <Typography variant="body2">{t('new_order.selected_address')}: {Boolean(getReturnType()) ? '1063 Budapest, Bajnok utca 13., 2. emelet(floor)' : displayAddress()}</Typography>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                checked={Boolean(getReturnType())}
                                onChange={e => setReturnType(e.target.checked ? 'TGS Iroda' : '')}
                            />}
                            label={<Typography variant="caption" color={theme.palette.primary[500]}>{t('new_order.take_back')}</Typography>}/>
                    </FormGroup>
                    <Typography variant="caption" color={theme.palette.primary[500]}>{t('new_order.type_address')}</Typography>
                    <GoogleMapsAutocomplete disabled={Boolean(getReturnType())} setAddress={setAddress}/>
                </Stack>
                <Button sx={{marginTop: 2}} onClick={() => {
                    setReturnAddress(address)
                    nextStep()
                }} disabled={!address?.coordinates && !Boolean(getReturnType())} variant="outlined"
                        endIcon={<NavigateNextRoundedIcon/>}>{t('general.next')}</Button>
            </Stack>
            <Map center={address?.coordinates}>
                {address?.coordinates && <Marker position={address?.coordinates}/>}
            </Map>
        </Stack>
    </Box>
}

export default SelectReturnAddress