import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    useTheme
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useContext, useEffect, useState} from "react";
import OrderContext from "../../../context/OrderContext";
import TourOfficeContext from "../../../context/TourOfficeContext";
import {useTranslation} from "react-i18next";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import TourGuideContext from "../../../context/TourGuideContext";

const SelectInvoiceData = () => {
    const {nextStep, invoiceDetailSelected, getTourOffice, setTourOffice, getInvoiceType, setInvoiceType, getContactPerson, setContactPerson} = useContext(OrderContext)
    const {tourOffices, newTourOfficeOpen} = useContext(TourOfficeContext)
    const {tourGuides, newTourGuideOpen} = useContext(TourGuideContext)
    const [invoice, setInvoice] = useState('choose')
    const [contact, setContact] = useState('me')
    const theme = useTheme()
    const {t} = useTranslation()

    useEffect(() => {
        if (tourOffices.length > 0) {
            setInvoice('choose')
            setTourOffice(tourOffices[tourOffices.length - 1]?.id)
        }
    }, [tourOffices])

    useEffect(() => {
        if (tourGuides.length > 0) {
            setContact('someone')
            setContactPerson(tourGuides[tourGuides.length - 1]?.id)
        }
    }, [tourGuides])

    return <Box className='white-card'>
        <Typography variant="headline4">{t('new_order.invoice_data')}</Typography>
        <Stack spacing={2} marginTop={2} marginBottom={2}>
            <Typography variant="caption" color={theme.palette.primary[500]}>{t('new_order.contact_person')}</Typography>
            <FormControl>
                <RadioGroup
                    aria-labelledby="contact-person-select"
                    name="invoice-contact"
                    value={contact}
                    onChange={e => {
                        setContact(e.target.value)
                        if (e.target.value === 'me') setContactPerson(null)
                    }}
                >
                    <FormControlLabel value="me" control={<Radio/>} label={t('new_order.contact_me')}/>
                    <FormControlLabel value="someone" control={<Radio/>} label={t('new_order.contact_someone')}/>
                    <FormControlLabel value="add" control={<Radio/>} label={t('new_order.add_now')}/>
                </RadioGroup>
            </FormControl>
            {contact === 'someone' && <FormControl>
                <Select
                    id="invoice-data-select-contact"
                    value={getContactPerson()}
                    variant='standard'
                    onChange={e => setContactPerson(e.target.value)}
                >
                    {tourGuides?.map(t => <MenuItem key={t.id} value={t.id}>{t?.first_name} {t?.last_name}</MenuItem>)}
                </Select>
            </FormControl>}
            {contact === 'add' && <Button variant='outlined' onClick={() => newTourGuideOpen(true)}>{t('new_order.add_now')}</Button>}
            <Typography variant="caption" color={theme.palette.primary[500]}>{t('new_order.invoice_date')}</Typography>
            <FormControl>
                <RadioGroup
                    aria-labelledby="invoice-data-select"
                    name="invoice-type"
                    value={getInvoiceType()}
                    onChange={e => setInvoiceType(e.target.value)}
                >
                    <FormControlLabel value="monthly" control={<Radio/>} label={t('new_order.collective_invoice')}/>
                    <FormControlLabel value="now" control={<Radio/>} label={t('new_order.asap_invoice')}/>
                </RadioGroup>
            </FormControl>
            <Typography variant="caption" color={theme.palette.primary[500]}>{t('new_order.invoice_data_select')}:</Typography>
            <FormControl>
                <RadioGroup
                    aria-labelledby="invoice-data-select"
                    name="payment"
                    value={invoice}
                    onChange={e => setInvoice(e.target.value)}
                >
                    <FormControlLabel value="now" control={<Radio/>} label={t('new_order.fill_invoice')}/>
                    <FormControlLabel value="choose" control={<Radio/>} label={t('new_order.select_invoice')}/>
                </RadioGroup>
            </FormControl>
            {invoice === 'choose' ? <FormControl>
                <Select
                    id="invoice-data-select"
                    value={getTourOffice()}
                    variant='standard'
                    onChange={e => setTourOffice(e.target.value)}
                >
                    {tourOffices?.map(t => <MenuItem key={t.id} value={t.id}>{t?.name}</MenuItem>)}
                </Select>
            </FormControl> : <Button variant='outlined' onClick={() => newTourOfficeOpen(true)}>{t('new_order.add_now')}</Button>}
         </Stack>
        <Button endIcon={<NavigateNextRoundedIcon/>} disabled={!invoiceDetailSelected()} variant='outlined' onClick={nextStep}>{t('general.next')}</Button>

    </Box>
}

export default SelectInvoiceData