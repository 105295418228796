import Typography from "@mui/material/Typography";
import Base from "../components/Base";
import sadCat from '../assets/sad-cat.gif'
import {useContext} from "react";
import AuthContext from "../context/AuthContext";
import AuthBase from "../components/AuthenticatedBase";
import {useTranslation} from "react-i18next";

const NotFound = () => {
    const {user} = useContext(AuthContext)
    const {t} = useTranslation()

    if (user) return <AuthBase label={t('404.heading')}>
        <div className="not-found-container">
            <Typography variant="headline2">{t('404.heading')}</Typography>
            <img src={sadCat} alt="sad dino" style={{width: '20%'}}/>
        </div>
    </AuthBase>
    else return <Base>
        <div className="not-found-container">
            <Typography variant="headline2">{t('404.heading')}</Typography>
            <img src={sadCat} alt="sad dino" style={{width: '20%'}}/>
        </div>
    </Base>

}

export default NotFound