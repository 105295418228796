import {Box, Button, Stack, Typography} from "@mui/material";
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import {useTranslation} from "react-i18next";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

const InvoiceData = ({id, name, short_name, country, zip_code, city, address, vat_number, is_company, deleteAction, editAction}) => {
    const {t} = useTranslation()

    return <Box className='invoice-data'>
        <Stack spacing={1}>
            <Stack direction='row' justifyContent='space-between'>
                <Stack spacing={1}>
                    <Typography variant='headline6'>{name}</Typography>
                    <Typography variant='body2'>{short_name}</Typography>
                </Stack>
                {is_company ? <ApartmentRoundedIcon/> : <Person2RoundedIcon/>}
            </Stack>
            <Typography variant='caption'>{t('invoice.vat_number')}: {vat_number}</Typography>
            <Typography variant='caption'>{t('invoice.address')}: {country?.code || country} {city} {zip_code} {address}</Typography>
            <Stack direction='row' justifyContent='space-between'>
                <Button endIcon={<EditRoundedIcon/>} variant='outlined' onClick={editAction}>{t('general.edit')}</Button>
                <Button endIcon={<DeleteRoundedIcon/>} variant='outlined' color='error' onClick={deleteAction}>{t('general.delete')}</Button>
            </Stack>
        </Stack>
    </Box>
}

export default InvoiceData