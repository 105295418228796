import * as React from 'react';
import {
    DataGridPro,
    LicenseInfo,
    huHU,
    GridToolbar,
} from '@mui/x-data-grid-pro';
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";


const TgsDataGrid = ({rows, columns}) => {
    LicenseInfo.setLicenseKey(
        process.env.REACT_APP_MUI,
    );
    const {t} = useTranslation()

    const localizedText = {
        // Define translated text for DataGrid elements
        noRowsLabel: t('datagrid.noRowsLabel'),
        columnsMenuLabel: t('datagrid.columns'),
        filterRowsBtnLabel: t('datagrid.filterRowsBtnLabel'),
        filterRowsMenuTitle: t('datagrid.filterRowsMenuTitle'),
        filterRowsMenuItemLabel: t('filterRowsMenuItemLabel.filter'),
        filterListHeader: t('datagrid.filterListHeader'),
        noResultsOverlayLabel: t('datagrid.noResultsOverlayLabel'),
        paginationRowsPerPage: t('datagrid.paginationRowsPerPage'),
        paginationFirstPage: t('datagrid.paginationFirstPage'),
        paginationLastPage: t('datagrid.paginationLastPage'),
        paginationNextPage: t('datagrid.paginationNextPage'),
        paginationPreviousPage: t('datagrid.paginationPreviousPage'),
        separator: t('datagrid.separator'),
        errorOverlayDefaultLabel: t('datagrid.errorOverlayDefaultLabel'),
        toolbarDensity: t('datagrid.toolbarDensity'),
        toolbarDensityLabel: t('datagrid.toolbarDensityLabel'),
        toolbarDensityCompact: t('datagrid.toolbarDensityCompact'),
        toolbarDensityStandard: t('datagrid.toolbarDensityStandard'),
        toolbarDensityComfortable: t('datagrid.toolbarDensityComfortable'),
        toolbarColumns: t('datagrid.toolbarColumns'),
        toolbarColumnsLabel: t('datagrid.toolbarColumnsLabel'),
        toolbarFilters: t('datagrid.toolbarFilters'),
        toolbarFiltersLabel: t('datagrid.toolbarFiltersLabel'),
        toolbarFiltersTooltipHide: t('datagrid.toolbarFiltersTooltipHide'),
        toolbarFiltersTooltipShow: t('datagrid.toolbarFiltersTooltipShow'),
        toolbarFiltersTooltipActive: t('datagrid.toolbarFiltersTooltipActive'),
        toolbarQuickFilterPlaceholder: t('datagrid.toolbarQuickFilterPlaceholder'),
        toolbarQuickFilterLabel: t('datagrid.toolbarQuickFilterLabel'),
        toolbarQuickFilterDeleteIconLabel: t('datagrid.toolbarQuickFilterDeleteIconLabel'),
        toolbarExport: t('datagrid.toolbarExport'),
        toolbarExportLabel: t('datagrid.toolbarExportLabel'),
        toolbarExportCSV: t('datagrid.toolbarExportCSV'),
        toolbarExportPrint: t('datagrid.toolbarExportPrint'),
        toolbarExportExcel: t('datagrid.toolbarExportExcel'),
        columnsPanelTextFieldLabel: t('datagrid.columnsPanelTextFieldLabel'),
        columnsPanelTextFieldPlaceholder: t('datagrid.columnsPanelTextFieldPlaceholder'),
        columnsPanelDragIconLabel: t('datagrid.columnsPanelDragIconLabel'),
        columnsPanelShowAllButton: t('datagrid.columnsPanelShowAllButton'),
        columnsPanelHideAllButton: t('datagrid.columnsPanelHideAllButton'),
        filterPanelAddFilter: t('datagrid.filterPanelAddFilter'),
        filterPanelDeleteIconLabel: t('datagrid.filterPanelDeleteIconLabel'),
        filterPanelLinkOperator: t('datagrid.filterPanelLinkOperator'),
        filterPanelOperators: t('datagrid.filterPanelOperators'),
        filterPanelOperatorAnd: t('datagrid.filterPanelOperatorAnd'),
        filterPanelOperatorOr: t('datagrid.filterPanelOperatorOr'),
        filterPanelColumns: t('datagrid.filterPanelColumns'),
        filterPanelInputLabel: t('datagrid.filterPanelInputLabel'),
        filterPanelInputPlaceholder: t('datagrid.filterPanelInputPlaceholder'),
        filterOperatorContains: t('datagrid.filterOperatorContains'),
        filterOperatorEquals: t('datagrid.filterOperatorEquals'),
        filterOperatorStartsWith: t('datagrid.filterOperatorStartsWith'),
        filterOperatorEndsWith: t('datagrid.filterOperatorEndsWith'),
        filterOperatorIs: t('datagrid.filterOperatorIs'),
        filterOperatorNot: t('datagrid.filterOperatorNot'),
        filterOperatorAfter: t('datagrid.filterOperatorAfter'),
        filterOperatorOnOrAfter: t('datagrid.filterOperatorOnOrAfter'),
        filterOperatorBefore: t('datagrid.filterOperatorBefore'),
        filterOperatorOnOrBefore: t('datagrid.filterOperatorOnOrBefore'),
        filterOperatorIsEmpty: t('datagrid.filterOperatorIsEmpty'),
        filterOperatorIsNotEmpty: t('datagrid.filterOperatorIsNotEmpty'),
        filterOperatorIsAnyOf: t('datagrid.filterOperatorIsAnyOf'),
        filterValueAny: t('datagrid.filterValueAny'),
        filterValueTrue: t('datagrid.filterValueTrue'),
        filterValueFalse: t('datagrid.filterValueFalse'),
        columnMenuLabel: t('datagrid.columnMenuLabel'),
        columnMenuShowColumns: t('datagrid.columnMenuShowColumns'),
        columnMenuFilter: t('datagrid.columnMenuFilter'),
        columnMenuHideColumn: t('datagrid.columnMenuHideColumn'),
        columnMenuUnsort: t('datagrid.columnMenuUnsort'),
        columnMenuSortAsc: t('datagrid.columnMenuSortAsc'),
        columnMenuSortDesc: t('datagrid.columnMenuSortDesc'),
        columnHeaderFiltersTooltipActive: t('datagrid.columnHeaderFiltersTooltipActive'),
        columnHeaderFiltersLabel: t('datagrid.columnHeaderFiltersLabel'),
        columnHeaderSortIconLabel: t('datagrid.columnHeaderSortIconLabel'),
        footerRowSelected: t('datagrid.footerRowSelected'),
        footerTotalRows: t('datagrid.footerTotalRows'),
        footerTotalVisibleRows: t('datagrid.footerTotalVisibleRows'),
        checkboxSelectionHeaderName: t('datagrid.checkboxSelectionHeaderName'),
        checkboxSelectionSelectAllRows: t('datagrid.checkboxSelectionSelectAllRows'),
        checkboxSelectionUnselectAllRows: t('datagrid.checkboxSelectionUnselectAllRows'),
        checkboxSelectionSelectRow: t('datagrid.checkboxSelectionSelectRow'),
        checkboxSelectionUnselectRow: t('datagrid.checkboxSelectionUnselectRow'),
        booleanCellTrueLabel: t('datagrid.booleanCellTrueLabel'),
        booleanCellFalseLabel: t('datagrid.booleanCellFalseLabel'),
        actionsCellMore: t('datagrid.actionsCellMore'),
        pinToLeft: t('datagrid.pinToLeft'),
        pinToRight: t('datagrid.pinToRight'),
        unpin: t('datagrid.unpin'),
        treeDataGroupingHeaderName: t('datagrid.treeDataGroupingHeaderName'),
        treeDataExpand: t('datagrid.treeDataExpand'),
        treeDataCollapse: t('datagrid.treeDataCollapse'),
        groupingColumnHeaderName: t('datagrid.groupingColumnHeaderName'),
        groupColumn: t('datagrid.groupColumn'),
        unGroupColumn: t('datagrid.unGroupColumn'),
        detailPanelToggle: t('datagrid.detailPanelToggle'),
        expandDetailPanel: t('datagrid.expandDetailPanel'),
        collapseDetailPanel: t('datagrid.collapseDetailPanel'),
        rowReorderingHeaderName: t('datagrid.rowReorderingHeaderName'),
        aggregationMenuItemHeader: t('datagrid.aggregationMenuItemHeader'),
        aggregationFunctionLabelSum: t('datagrid.aggregationFunctionLabelSum'),
        aggregationFunctionLabelAvg: t('datagrid.aggregationFunctionLabelAvg'),
        aggregationFunctionLabelMin: t('datagrid.aggregationFunctionLabelMin'),
        aggregationFunctionLabelMax: t('datagrid.aggregationFunctionLabelMax'),
        aggregationFunctionLabelSize: t('datagrid.aggregationFunctionLabelSize'),
        MuiTablePagination: t('datagrid.MuiTablePagination'),
    };

    return <Box sx={{height: '70vh', width: '100%'}}>
        <DataGridPro
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick={true}
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            localeText={localizedText}
        />
    </Box>
}

export default TgsDataGrid