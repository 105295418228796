import {createContext, useContext, useEffect, useRef, useState} from "react";
import useCallDataApi from "../hooks/data";
import TgsDialog from "../components/Dialog";
import InvoiceDataData from "../pages/invoices/invoiceDataData";
import Loading from "../components/Loading";
import {Button, Stack} from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AuthContext from "./AuthContext";
import {useTranslation} from "react-i18next";
import {enqueueSnackbar, useSnackbar} from "notistack";
import {cleanData} from "../utils/utils";
import TourGuideData from "../pages/tourGuides/TourGuideData";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';

const TourGuideContext = createContext();

export default TourGuideContext;

export const TourGuideProvider = ({children}) => {
    const {t} = useTranslation()
    const {
        getData: getTourGuides,
        postData: postTourGuide,
        updateData: updateTourGuide,
    } = useCallDataApi('core/client-tour-guides')
    const [current, setCurrent] = useState(-1)
    const [tourGuides, setTourGuides] = useState([])
    const [newOpen, setNewOpen] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const tourGuideForm = useRef(null)
    const snackbar = useSnackbar()

    useEffect(() => {
        getTourGuides().then(d => setTourGuides(d)).finally(() => setLoading(false))
    }, [])

    const findTourGuide = id => tourGuides?.find(t => t.id === id)

    const newTourGuide = data => {
        setLoading(true)
        postTourGuide('', data).then(d => {
            setTourGuides([...tourGuides, d])
            snackbar.enqueueSnackbar(t('general.added'), {variant: 'success'})
        }).finally(() => setLoading(false))
    }

    const removeTourGuide = id => {
        setLoading(true)
        getTourGuides(`${current}/invalidate/`).then(() => {
            setTourGuides([...tourGuides.filter(d => d?.id !== id)])
            snackbar.enqueueSnackbar(t('general.deleted'), {variant: 'success'})
        }).finally(() => setLoading(false))
    }

    const editTourGuide = data => {
        setLoading(true)
        updateTourGuide(`${current}/`, data).then(d => {
            const indexOfCurrent = tourGuides.findIndex(t => t.id === d?.id)
            const newTourGuides = [...tourGuides]
            newTourGuides[indexOfCurrent] = d
            setTourGuides([...newTourGuides])
            setEditOpen(false)
            snackbar.enqueueSnackbar(t('general.edited'), {variant: 'success'})
        }).finally(() => setLoading(false))
    }

    const contextData = {
        newTourGuideOpen: setNewOpen,
        editTourGuideOpen: setEditOpen,
        deleteTourGuideOpen: setDeleteOpen,
        setCurrentTourGuide: setCurrent,
        findTourGuide,
        tourGuides,
    }

    return <TourGuideContext.Provider value={contextData}>
        {children}
        <TgsDialog
            title={t('tour_guides.new_title')}
            open={newOpen}
            handleClose={() => setNewOpen(false)}
            subtitle={t('tour_guides.new_subtitle')}
            actions={<Stack spacing={2} direction='row'>
                <Button variant='outlined' onClick={() => setNewOpen(false)}>{t('general.cancel')}</Button>
                <Button variant='outlined' onClick={() => {
                    const rawData = tourGuideForm?.current?.getData()
                    if (rawData) {
                        const data = cleanData(rawData)
                        newTourGuide(data)
                        setNewOpen(false)
                    }
                }} color='success' endIcon={<SaveRoundedIcon/>}>{t('general.save')}</Button>
            </Stack>}>
            <TourGuideData reference={tourGuideForm}/>
        </TgsDialog>

        <TgsDialog
            title={`${findTourGuide(current)?.last_name} ${findTourGuide(current)?.first_name} ${t('general.dialog_delete')}`}
            open={deleteOpen}
            handleClose={() => setDeleteOpen(false)}
            subtitle={t('tour_guides.delete_subtitle')}
            actions={<Stack spacing={2} direction='row'>
                <Button variant='outlined' onClick={() => setDeleteOpen(false)}>{t('genral.cancel')}</Button>
                <Button variant='outlined' onClick={() => {
                    setDeleteOpen(false)
                    removeTourGuide(current)
                }} color='error' endIcon={<DeleteRoundedIcon/>}>{t('general.delete')}</Button>
            </Stack>}/>

        <TgsDialog
            title={`${findTourGuide(current)?.last_name} ${findTourGuide(current)?.first_name} ${t('general.dialog_edit')}`}
            open={editOpen}
            handleClose={() => setEditOpen(false)}
            subtitle={t('tour_guides.edit_subtitle')}
            actions={<Stack spacing={2} direction='row'>
                <Button variant='outlined' onClick={() => setEditOpen(false)}>{t('general.cancel')}</Button>
                <Button variant='outlined' onClick={() => {
                    const rawData = tourGuideForm.current.getData()
                    if (rawData) {
                        const data = cleanData(rawData)
                        editTourGuide(data)
                        setEditOpen(false)
                    }
                }} color='success' endIcon={<EditRoundedIcon/>}>{t('general.edit')}</Button>
            </Stack>}>
            <TourGuideData reference={tourGuideForm} obj={findTourGuide(current)} />
        </TgsDialog>

        <Loading isLoading={loading}/>
    </TourGuideContext.Provider>
}