import {Grid, MenuItem, Select, Stack, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import tgsLogo from "../assets/tgs_logo.png"
// import simpleLogo from '../assets/simplepay_200x50.png'
import {HashLink} from "react-router-hash-link";
import SocialButton from "./SocialButton";
import {availableLanguages} from "../utils/countries";
import {useContext} from "react";
import ConfigContext from "../context/ConfigContext";
import {useTranslation} from "react-i18next";


const TgsFooter = () => {
    const theme = useTheme()
    const {language, setAppLanguage} = useContext(ConfigContext)
    const {t} = useTranslation()

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -117;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    const primaryColor = {color: theme.palette.primary[700]}

    return <div className="dino-footer">
        <div className="dino-footer-content">
            <Grid container spacing={12}>
                <Grid item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}} style={{marginBottom: '20px'}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>{t('footer.contact')}</Typography>
                        <Typography variant="buttonNormal" style={primaryColor}>1063 Budapest, Bajnok utca 13., 2. emelet </Typography>
                        <Typography variant="button" style={primaryColor}>+36 30 604 9320</Typography>
                        <Typography variant="button" style={primaryColor}>booking@tourguidesystem.hu</Typography>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <SocialButton onClick={() => window.open('https://www.facebook.com/tourguidesystems', '_blank')} inverted socialIcon={<FacebookOutlinedIcon/>}/>
                        <SocialButton onClick={() => window.open('https://instagram.com/tourguidesystems', '_blank')} inverted socialIcon={<InstagramIcon/>}/>
                        <SocialButton onClick={() => window.open('https://youtube.com/@tourguidesystems', '_blank')} inverted socialIcon={<YouTubeIcon/>}/>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>{t('footer.sections')}</Typography>
                        <HashLink to="/" scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="buttonNormal" style={primaryColor}>{t('footer.home')}</Typography>
                        </HashLink>
                        <HashLink to="/login" scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="buttonNormal" style={primaryColor}>{t('general.login')}</Typography>
                        </HashLink>
                        <HashLink to="/register" scroll={el => scrollWithOffset(el)} style={{textDecoration: 'none'}}>
                            <Typography variant="buttonNormal" style={primaryColor}>{t('general.register')}</Typography>
                        </HashLink>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>{t('footer.docs')}</Typography>
                        <Typography variant="buttonNormal"><a style={primaryColor} target='_blank' rel="noreferrer" href="https://www.tourguidesystem.hu/_files/ugd/94e7e8_761d23548ac040f39c0debfd17a23f9f.pdf">{t('footer.privacy')}</a></Typography>
                        <Typography variant="buttonNormal"><a style={primaryColor} target='_blank' rel="noreferrer" href="https://www.tourguidesystem.hu/_files/ugd/94e7e8_4f6ef728d34c42ee9d010006bb388e26.pdf">{t('footer.aszf')}</a></Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <Stack spacing={{xs: 2, md: 3, xl: 4}}>
                        <Typography variant="caption" style={{color: '#A9BBC2'}}>{t('footer.lang')}</Typography>
                        <Select
                            id="country-select-demo"
                            // autoHighlight
                            value={language}
                            onChange={(e) => setAppLanguage(e.target.value)}
                            variant='standard'
                        >
                            {availableLanguages?.map(option => <MenuItem value={option.lang} key={option.lang} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    alt=""
                                />
                            </MenuItem>)}
                        </Select>
                      </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <div className="dino-footer-container__own-logo">
                        <img src={tgsLogo} alt="Tgs logo" width="150px"/>
                        {/*<img onClick={() => window.location.href = 'https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf'} src={simpleLogo} alt="SimplePay logo" style={{cursor: 'pointer'}} width="150px"/>*/}
                    </div>
                </Grid>
            </Grid>

        </div>
    </div>

}

export default TgsFooter