import {createContext, useState, useEffect, useCallback, useContext} from "react";
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import ConfigContext from "./ConfigContext";
import {useSnackbar} from "notistack";
import axios from "axios";
import i18n from "../i18n";


const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {
    const {baseUrl} = useContext(ConfigContext)
    const [authTokens, setAuthTokens] = useState(() => localStorage.getItem("authTokens") ? JSON.parse(localStorage.getItem("authTokens")) : null);
    const [user, setUser] = useState(() => localStorage.getItem("authTokens") ? jwt_decode(localStorage.getItem("authTokens")) : null);
    const [permissions, setPermissions] = useState(() => localStorage.getItem("permissions") ? localStorage.getItem("permissions") : [])
    const [role, setRole] = useState(() => localStorage.getItem("role") ? localStorage.getItem("role") : 'employee')
    const [authLoading, setAuthLoading] = useState(false)
    const history = useHistory();
    const snackbar = useSnackbar()

    const api = axios.create({
        baseURL: baseUrl,
        headers: {"Content-Type": "application/json"}
    })

    const setAuthStorage = (data) => {
        setAuthTokens(data);
        const u = jwt_decode(data.access);
        setUser(u);
        setPermissions(u.permissions);
        setRole(u?.role)
        localStorage.setItem("authTokens", JSON.stringify(data));
        localStorage.setItem("permissions", u?.permissions);
        localStorage.setItem("role", u?.role);
        return u?.role
    }

    const loginUser = (data) => {
        api.post('api/token/', JSON.stringify(data))
            .then(r => {
                const data = r?.data
                const role = setAuthStorage(data)
                history.push('/')
            })
            .catch(e => {
                const status = e?.response?.status
                if (status === 401) snackbar.enqueueSnackbar(i18n.t('errors.wrongPassword'), {variant: 'error'})
                else snackbar.enqueueSnackbar(i18n.t('errors.login'), {variant: 'error'})
                console.log(e)
            }).finally(() => setAuthLoading(false))
    }

    const registerUser = (data) => {
        if (data.password !== data.password_again) {
            snackbar.enqueueSnackbar(i18n.t('errors.validateEqualsPassword'), {variant: 'error'})
            return
        }
        setAuthLoading(true)
        let url = 'core/register_new/';
        console.log(url)
        api.post(url, JSON.stringify(data))
            .then(r => {
                history.push('/success')
            })
            .catch(e => {
                const message = e?.response?.data?.message
                if (message) snackbar.enqueueSnackbar(message, {variant: 'error'})
                else snackbar.enqueueSnackbar(i18n.t('errors.unexpected'), {variant: 'error'})
                console.log(e)
            })
            .finally(() => setAuthLoading(false))
    }

    const logoutUser = useCallback(() => {
        setAuthTokens(null)
        setUser(null)
        setPermissions(null)
        setRole(null)
        localStorage.removeItem("authTokens")
        localStorage.removeItem("permissions")
        localStorage.removeItem("role")
    }, []);

    const updateToken = useCallback( () => {
        api.post('api/token/refresh/', JSON.stringify({refresh: authTokens?.refresh}))
            .then(r => setAuthStorage(r?.data))
            .catch(e => logoutUser())
    }, [authTokens?.refresh, logoutUser])

    const forgotPassword = (data) => {
        setAuthLoading(true)
        api.post('core/password/send_link/', JSON.stringify(data))
            .then(r => {
                setAuthLoading(false)
                snackbar.enqueueSnackbar(i18n.t('general.emailSent'), {variant: 'success'})
                history.push("/")
            })
            .catch(e => {
                setAuthLoading(false)
                snackbar.enqueueSnackbar(i18n.t('errors.unexpected'), {variant: 'error'})
                console.log(e)
            })
    }

    const newPassword = (data) => {
        setAuthLoading(true)
        api.post('password/change_password/', JSON.stringify(data))
            .then(r => {
                setAuthLoading(false)
                snackbar.enqueueSnackbar(i18n.t('general.passwordChanged'), {variant: 'success'})
                history.push("/login")
            })
            .catch(e => {
                setAuthLoading(true)
                snackbar.enqueueSnackbar(i18n.t('errors.unexpected'), {variant: 'error'})
                console.log(e)
            })
    }

    const contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: loginUser,
        registerUser: registerUser,
        logoutUser: logoutUser,
        permissions: permissions,
        forgotPassword: forgotPassword,
        newPassword: newPassword,
        authLoading: authLoading,
        setAuthLoading: setAuthLoading,
        role: role,
        updateToken: updateToken
    };

    useEffect(() => {
        const fourMinutes = 1000 * 60 * 4
        const interval = setInterval(() => {
            if (authTokens) {
                updateToken()
            }
        }, fourMinutes);
        return () => clearInterval(interval)
    }, [authTokens, updateToken])

    return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};