import {createContext, useContext, useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";
import {enqueueSnackbar} from "notistack";
import Loading from "../components/Loading";
import {useHistory} from "react-router-dom";
import {daysBetween, formatHuman} from "../utils/utils";
import AuthContext from "./AuthContext";
import {useTranslation} from "react-i18next";

const OrderContext = createContext();

export default OrderContext;

export const OrderProvider = ({children}) => {
    const {user} = useContext(AuthContext)
    const [stepNumber, setStepNumber] = useState(0)
    const steps = [
        {
            key: 'date',
            i18n: 'new_order.order_dates'
        }, {
            key: 'delivery_address',
            i18n: 'new_order.delivery_address'
        }, {
            key: 'return_address',
            i18n: 'new_order.return_address'
        }, {
            key: 'devices',
            i18n: 'new_order.devices'
        }, {
            key: 'invoice',
            i18n: 'new_order.invoice_data'
        }, {
            key: 'summary',
            i18n: 'new_order.summary'
        }]
    const [discount, setDiscount] = useState(null)
    const [availability, setAvailability] = useState({})
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()
    const history = useHistory()
    const {
        getData: getOrders,
        postData: postOrder,
    } = useCallDataApi('core/client-orders')

    const initialState = {
        delivery_address: {
            coordinates: null,
            address: "",
            address_com: ""
        },
        return_address: {
            coordinates: null,
            address: "",
            address_com: ""
        },
        delivery_date: null,
        delivery_time: null,
        return_date: null,
        return_time: null,
        delivery_type: '',
        return_type: '',
        order_rx: 1,
        order_tx: 1,
        tour_office: null,
        code: null,
        invoice_type: 'now',
        tour_guide: null,
        comment: null
    };
    const [selected, setSelected] = useState(initialState)

    const nextStep = () => {
        if (stepNumber === steps.length - 1) return
        setStepNumber(prev => prev + 1)
    }
    const prevStep = () => {
        if (stepNumber === 0) return
        setStepNumber(prev => prev - 1)
    }

    const goToStep = stepIndex => {
        if (stepIndex > steps.length - 1) return
        if (stepIndex < 0) return
        if (stepIndex > stepNumber) return
        setStepNumber(stepIndex)
    }

    const setDeliveryAddress = (address) => setSelected({
        ...selected,
        delivery_address: {address: address?.address, coordinates: address?.coordinates, address_com: address?.address_com}
    })

    const getDeliveryAddress = () => selected?.delivery_address

    const setReturnAddress = (address) => setSelected({
        ...selected,
        return_address: {address: address?.address, coordinates: address?.coordinates, address_com: address?.address_com}
    })

    const getReturnAddress = () => selected?.return_address

    const getStartDate = () => selected?.delivery_date

    const getEndDate = () => selected?.return_date

    const setDates = (dateList) => setSelected({...selected, delivery_date: dateList[0], return_date: dateList[1]})

    const getDeliveryTime = () => selected?.delivery_time || 'EN'

    const getReturnTime = () => selected?.return_time || 'EN'

    const setDeliveryTime = time => setSelected({...selected, delivery_time: time})

    const setReturnTime = time => setSelected({...selected, return_time: time})

    const setDeliveryType = t => setSelected({...selected, delivery_type: t})

    const getDeliveryType = () => selected?.delivery_type

    const setReturnType = t => setSelected({...selected, return_type: t})

    const getReturnType = () => selected?.return_type

    const getOrderRx = () => selected?.order_rx

    const setOrderRx = v => setSelected({...selected, order_rx: v})

    const getOrderTx = () => selected?.order_tx

    const setOrderTx = v => setSelected({...selected, order_tx: v})

    const getTourOffice = () => selected?.tour_office

    const setTourOffice = v => setSelected({...selected, tour_office: v})

    const getCode = () => selected?.code

    const setCode = code => setSelected({...selected, code: code})

    const getComment = () => selected?.comment

    const setComment = comment => setSelected({...selected, comment: comment})

    const getInvoiceType = () => selected?.invoice_type

    const setInvoiceType = type => setSelected({...selected, invoice_type: type})

    const getDiscount = () => discount

    const deleteDiscount = () => setDiscount(null)

    const invoiceDetailSelected = () => selected?.tour_office !== null

    const displayDeliveryAddress = () => `${selected?.delivery_address?.address?.country || ''}, ${selected?.delivery_address?.address?.city || ''} ${selected?.delivery_address?.address?.postal_code || ''} ${selected?.delivery_address?.address?.street || ''} ${selected?.delivery_address?.address?.street_number || ''}`

    const displayReturnAddress = () => `${selected?.return_address?.address?.country || ''}, ${selected?.return_address?.address?.city || ''} ${selected?.return_address?.address?.postal_code || ''} ${selected?.return_address?.address?.street || ''} ${selected?.return_address?.address?.street_number || ''}`

    const getContactPerson = () => selected?.tour_guide

    const setContactPerson = contactPerson => setSelected({...selected, tour_guide: contactPerson})

    const newOrder = () => {
        setSelected(initialState)
        setStepNumber(0)
    }

    const rentType = () => {
        const numberOfDays = daysBetween(selected?.return_date, selected?.delivery_date)
        const LONG_RENT_MIN_DAYS = 5
        return numberOfDays >= LONG_RENT_MIN_DAYS ? 'long_rent' : 'short_rent'
    }

    const calculateUrgencyFee = () => {
        if (!isUrgent()) return 0
        if (getOrderRx() + getOrderTx() >= 25) return 3500
        return 2500
    }

    const calculatePrice = () => {
        const CURRENCY = 'HUF'
        const dailyPrice = user[`${rentType()}_${CURRENCY}`]
        // console.log(`dailyPrice: ${dailyPrice} * order_rx: ${selected?.order_rx} * daysBetween: ${daysBetween(selected?.return_date, selected?.delivery_date)} + calculateUrgencyFee: ${calculateUrgencyFee()}`)
        return calculateUrgencyFee() + selected?.order_rx * dailyPrice * (daysBetween(selected?.return_date, selected?.delivery_date) + 1)
    }

    const applyDiscount = price => {
        if (discount?.minus) return Math.max(0, price - discount.minus)
        return Math.max(0, price - (price * discount.percent))
    }

    const validateCouponCode = () => {
        setLoading(true)
        getOrders(`validate_coupon_code/?code=${selected?.code}`)
            .then(r => setDiscount(r))
            .catch(e => enqueueSnackbar({message: t('errors.no_code'), variant: 'error'}))
            .finally(() => setLoading(false))
    }

    const getAvailability = (startDate, endDate) => {
        setLoading(true)
        getOrders(`check_availability/?start_date=${formatHuman(startDate)}&end_date=${formatHuman(endDate)}`)
            .then(a => {
                setAvailability(a)
            }).finally(() => setLoading(false))
    }

    const placeOrder = () => {
        setLoading(true)
        const correction = {
            delivery_date: formatHuman(selected?.delivery_date),
            return_date: formatHuman(selected?.return_date),
            delivery_address: JSON.stringify(selected?.delivery_address),
            return_address: JSON.stringify(selected?.return_address),
        }
        if (['EN', 'DE', 'DU'].includes(getDeliveryTime())) {
            correction['delivery_time'] = null
            correction['delivery_period'] = getDeliveryTime()
        }
        if (['EN', 'DE', 'DU'].includes(getReturnTime())) {
            correction['return_time'] = null
            correction['return_period'] = getReturnTime()
        }
        postOrder('place_order/', {
            ...selected,
            ...correction,
        })
            .then(r => history.push('/successful-order'))
            .catch(r => enqueueSnackbar({message: t('errors.unexpected'), variant: 'error'}))
            .finally(() => setLoading(false))
    }

    const startDateIsNextWorkday = () => {
        let currentDate = new Date()
        currentDate.setDate(currentDate.getDate() + 1)
        while (currentDate < selected?.delivery_date) {
            if (availability[formatHuman(currentDate)] === false) return false
            currentDate.setDate(currentDate.getDate() + 1)
        }
        return true
    }

    const isCurrentTimeLaterThanGivenTime = (givenTime) => {
        const currentTime = new Date()
        const givenTimeString = givenTime.split(':')
        const givenHour = parseInt(givenTimeString[0], 10)
        const givenMinute = parseInt(givenTimeString[1], 10)
        const givenDateTime = new Date()
        givenDateTime.setHours(givenHour, givenMinute, 0, 0)
        return currentTime.getTime() > givenDateTime.getTime()
    }

    const deliveryTimeBeforeNoon = () => {
        const beforeNoonOptions = ['DE', '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45']
        return beforeNoonOptions.includes(selected?.delivery_time)
    }

    const isUrgent = () => startDateIsNextWorkday() && deliveryTimeBeforeNoon() && isCurrentTimeLaterThanGivenTime('14:00')
    const isOnlyPersonalPickup = () => startDateIsNextWorkday() && deliveryTimeBeforeNoon() && isCurrentTimeLaterThanGivenTime('17:00')

    const checkDates = () => {
        if (isUrgent()) enqueueSnackbar('Sürgősségi felár', {variant: 'warning'})
        if (isOnlyPersonalPickup()) enqueueSnackbar('Csak szeélyes átvétel', {variant: 'warning'})
    }

    useEffect(() => {
        checkDates()
    }, [selected?.delivery_date, selected?.return_date, selected?.delivery_time, selected?.return_time])

    const contextData = {
        setDeliveryAddress,
        getDeliveryAddress,
        setReturnAddress,
        getReturnAddress,
        getStartDate,
        getEndDate,
        setDates,
        getDeliveryTime,
        getReturnTime,
        setDeliveryTime,
        setReturnTime,
        setDeliveryType,
        getDeliveryType,
        setReturnType,
        getReturnType,
        getOrderRx,
        setOrderRx,
        getOrderTx,
        setOrderTx,
        getTourOffice,
        setTourOffice,
        getInvoiceType,
        setInvoiceType,
        getCode,
        setCode,
        getComment,
        setComment,
        getContactPerson,
        setContactPerson,
        validateCouponCode,
        placeOrder,
        getDiscount,
        displayDeliveryAddress,
        displayReturnAddress,
        deleteDiscount,
        calculatePrice,
        applyDiscount,
        invoiceDetailSelected,
        getAvailability,
        isUrgent,
        isOnlyPersonalPickup,
        availability,
        stepNumber,
        steps,
        nextStep,
        prevStep,
        goToStep,
        newOrder
    }

    return <OrderContext.Provider value={contextData}>
        {children}
        <Loading isLoading={loading}/>
    </OrderContext.Provider>
}