import {Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import CustomTextField, {validateRequired, validateEmail} from "../../components/CustomTextFiled";
import ErrorHandlingForm from "../../components/ErrorHandlingForm";
import {useTranslation} from "react-i18next";

const TourGuideData = ({reference, obj}) => {
    const {t} = useTranslation()
    const [formData, setFormData] = useState({
        first_name: obj?.first_name || '',
        last_name: obj?.last_name || '',
        email: obj?.email || '',
        phone: obj?.phone || '',
    })

    const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        email: false,
        phone: false,
    })

    const validators = {
        first_name: [validateRequired],
        last_name: [validateRequired],
        email: [validateRequired, validateEmail],
        phone: [validateRequired],
    };

    return <ErrorHandlingForm reference={reference} errors={errors} setErrors={setErrors} data={formData} setData={setFormData} validators={validators} style={{marginTop: '15px'}}>
        <Stack spacing={2}>
            <Typography>{t('tour_guide.first_name')}</Typography>
            <CustomTextField
                placeholder={t('tour_guide.first_name')}
                name="first_name"
                variant="standard"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
            <Typography>{t('tour_guide.last_name')}</Typography>
            <CustomTextField
                placeholder={t('tour_guide.last_name')}
                name="last_name"
                variant="standard"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
            <Typography>{t('tour_guide.email')}</Typography>
            <CustomTextField
                placeholder={t('tour_guide.email')}
                name="email"
                variant="standard"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />
            <Typography>{t('tour_guide.phone')}</Typography>
            <CustomTextField
                placeholder={t('tour_guide.phone')}
                name="phone"
                variant="standard"
                data={formData}
                setData={setFormData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
            />

        </Stack>
    </ErrorHandlingForm>
}

export default TourGuideData