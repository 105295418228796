import React, {useState} from 'react'
import {MuiTelInput, matchIsValidTel} from 'mui-tel-input'
import i18n from '../i18n'


const ErrorHandlingPhoneField = ({validators, value, onValChange, check, ...rest}) => {
    const [error, setError] = useState('')

    const validate = (val) => {
        setError('')
        for (let validator of validators) {
            const validatedResult = validator(val)
            if (!validatedResult.result) {
                if (check) check(true)
                return setError(validatedResult.error)
            }
        }
        if (check) check(false)
    }

    return <MuiTelInput
        error={Boolean(error)}
        helperText={error}
        {...rest}
        value={value}
        onChange={e => {
            onValChange(e)
            validate(e)
        }}
    />
}

const validatePhoneNumber = (value) => {
    return {
        result: matchIsValidTel(value),
        error: i18n.t('errors.validatePhoneNumber')
    }
}

const validatePhoneRequired = (value) => {
    return {
        result: Boolean(value),
        error: i18n.t('errors.validateRequired')
    }
}

export default ErrorHandlingPhoneField
export {validatePhoneNumber, validatePhoneRequired}