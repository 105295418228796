import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {validatePhoneNumber} from "./components/ErrorHandlingPhoneField";

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'hu',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    datagrid: {
                        noRowsLabel: 'No rows',
                        columns: 'Columns',
                        filterRowsBtnLabel: 'Filter rows',
                        filterRowsMenuTitle: 'Filter rows menu',
                        filterRowsMenuItemLabel: 'Filter',
                        filterListHeader: 'Filter list header',
                        noResultsOverlayLabel: 'No results overlay',
                        paginationRowsPerPage: 'Rows per page',
                        paginationFirstPage: 'First page',
                        paginationLastPage: 'Last page',
                        paginationNextPage: 'Next page',
                        paginationPreviousPage: 'Previous page',
                        separator: ',',
                        errorOverlayDefaultLabel: 'Default Error',
                        toolbarDensity: 'Density',
                        toolbarDensityLabel: 'Density',
                        toolbarDensityCompact: 'Compact',
                        toolbarDensityStandard: 'Standard',
                        toolbarDensityComfortable: 'Comfortable',
                        toolbarColumns: 'Columns',
                        toolbarColumnsLabel: 'Columns',
                        toolbarFilters: 'Filters',
                        toolbarFiltersLabel: 'Filters',
                        toolbarFiltersTooltipHide: 'Hide tooltip',
                        toolbarFiltersTooltipShow: 'Show tooltip',
                        toolbarFiltersTooltipActive: 'Active tooltip',
                        toolbarQuickFilterPlaceholder: 'Quick filter',
                        toolbarQuickFilterLabel: 'Quick filter',
                        toolbarQuickFilterDeleteIconLabel: 'Delete Icon',
                        toolbarExport: 'Export',
                        toolbarExportLabel: 'Export label',
                        toolbarExportCSV: 'CSV',
                        toolbarExportPrint: 'Print',
                        toolbarExportExcel: 'Excel',
                        columnsPanelTextFieldLabel: 'Columns',
                        columnsPanelTextFieldPlaceholder: 'Text field',
                        columnsPanelDragIconLabel: 'Drag icon',
                        columnsPanelShowAllButton: 'Show all buttons',
                        columnsPanelHideAllButton: 'Hide all buttons',
                        filterPanelAddFilter: 'Add filter',
                        filterPanelDeleteIconLabel: 'Delete filter',
                        filterPanelLinkOperator: 'Link operator',
                        filterPanelOperators: 'Panel operator',
                        filterPanelOperatorAnd: 'AND filter',
                        filterPanelOperatorOr: 'OR filter',
                        filterPanelColumns: 'Filter coloumns',
                        filterPanelInputLabel: 'Input',
                        filterPanelInputPlaceholder: 'Panel input',
                        filterOperatorContains: 'Contains',
                        filterOperatorEquals: 'Equals',
                        filterOperatorStartsWith: 'Starts with',
                        filterOperatorEndsWith: 'Ends with',
                        filterOperatorIs: 'Is',
                        filterOperatorNot: 'Not',
                        filterOperatorAfter: 'After',
                        filterOperatorOnOrAfter: 'On or after',
                        filterOperatorBefore: 'Before',
                        filterOperatorOnOrBefore: 'On or before',
                        filterOperatorIsEmpty: 'Is empty',
                        filterOperatorIsNotEmpty: 'Is not empty',
                        filterOperatorIsAnyOf: 'Is any of',
                        filterValueAny: 'Any',
                        filterValueTrue: 'True',
                        filterValueFalse: 'False',
                        columnMenuLabel: 'Column menu',
                        columnMenuShowColumns: 'Show columns',
                        columnMenuFilter: 'Menu filter',
                        columnMenuHideColumn: 'Hide column',
                        columnMenuUnsort: 'Unsort menu',
                        columnMenuSortAsc: 'Ascending',
                        columnMenuSortDesc: 'Descending',
                        columnHeaderFiltersTooltipActive: 'Active tooltip',
                        columnHeaderFiltersLabel: 'Header filter',
                        columnHeaderSortIconLabel: 'Sort icon',
                        footerRowSelected: 'Selected row',
                        footerTotalRows: 'Total',
                        footerTotalVisibleRows: 'Total visible rows',
                        checkboxSelectionHeaderName: 'Header name selection',
                        checkboxSelectionSelectAllRows: 'Select all rows',
                        checkboxSelectionUnselectAllRows: 'Unselect all rows',
                        checkboxSelectionSelectRow: 'Select row',
                        checkboxSelectionUnselectRow: 'Unselect row',
                        booleanCellTrueLabel: 'True',
                        booleanCellFalseLabel: 'False',
                        actionsCellMore: 'More actions',
                        pinToLeft: 'Pin to left',
                        pinToRight: 'Pin to right',
                        unpin: 'unpin',
                        treeDataGroupingHeaderName: 'Group data header name',
                        treeDataExpand: 'Expand data',
                        treeDataCollapse: 'Collapse data',
                        groupingColumnHeaderName: 'Group column header',
                        groupColumn: 'Group column',
                        unGroupColumn: 'Ungroup column',
                        detailPanelToggle: 'Toggle panel',
                        expandDetailPanel: 'Expand detail panel',
                        collapseDetailPanel: 'Collapse detail panel',
                        rowReorderingHeaderName: 'Header name',
                        aggregationMenuItemHeader: 'Menu item',
                        aggregationFunctionLabelSum: 'Label sum',
                        aggregationFunctionLabelAvg: 'Label average',
                        aggregationFunctionLabelMin: 'Label min',
                        aggregationFunctionLabelMax: 'Label max',
                        aggregationFunctionLabelSize: 'Label size',
                        MuiTablePagination: 'Pagination',
                    },
                    menu: {
                        orders: 'Orders',
                        invoices: 'Invoices',
                        logout: 'Logout',
                        sure_logout: 'Are you sure you want to logout?',
                        tour_guides: 'Contacts'
                    },
                    footer: {
                        contact: 'Contact',
                        sections: 'Sections',
                        home: 'Home',
                        docs: 'Documents',
                        privacy: 'Privacy policy',
                        aszf: 'Terms and conditions',
                        lang: 'Language'
                    },
                    general: {
                        login: 'Login',
                        register: 'Register',
                        cancel: 'Cancel',
                        send: 'Send',
                        or: 'Or',
                        next: 'Next',
                        manage: 'Manage',
                        back: 'Back',
                        price: 'Price',
                        edit: 'Edit',
                        save: 'Save',
                        delete: 'Delete',
                        add: 'Add',
                        emailSent: 'Email sent!',
                        passwordChanged: 'Password changed',
                        dialog_edit: 'Edit details',
                        comment: 'Comment',
                        comment_description: 'Special requests, etc..., your order will go into a review status before accepting'
                    },
                    errors: {
                        unexpected: 'An unexpected error occurred!',
                        no_code: 'No valid coupon code was found!',
                        network: 'Service currently unavailable!',
                        wrongPassword: 'Wrong password or email!',
                        login: 'Error during login, please try again later!',
                        validateChars: 'Can only contain unaccented lowercase characters (a-z)',
                        validateRequired: 'This field is required',
                        validateMinLength: 'This text needs to be at least {{minTextLength}} characters long',
                        validateMaxLength: 'This text can only be {{maxTextLength}} characters long',
                        validateEmail: 'Not a valid email address',
                        validateEqualsPassword: 'The two passwords must be the same',
                        validatePassword: 'Must contain a special character (!@#$%^&*(),.?":{}|<>) and a number',
                        validatePhoneNumber: 'Not a valid phone number',
                        validateVatNumber: 'A vat number should be formatted like: 12345678-1-12'


                    },
                    orders: {
                        success: 'Successful order',
                        successfulOrder: 'Successful order!',
                        title: 'Orders',
                        new: 'New order',
                        order_number: 'Order number',
                        comment: 'Comment',
                        order_status: 'Order status',
                        tour_type: 'Tour type',
                        tour_code: 'Tour code',
                        delivery_date: 'Delivery date',
                        delivery_period: 'Delivery period',
                        days_before: 'Days before',
                        download_invoice: "Download invoice",
                        delivery_type: 'Delivery type',
                        delivery_time: 'Delivery time',
                        invoice_nuumber: 'Invoice number',
                        return_time: 'Return time',
                        delivery_address: 'Delivery address',
                        delivery_format: 'Delivery format',
                        return_date: 'Return date',
                        days_after: 'Days after',
                        return_type: 'Return type',
                        return_address: 'Return address',
                        returned_rx: 'Returned receivers',
                        returned_tx: 'Returned transmitters',
                        order_rx: 'Ordered receivers',
                        order_tx: 'Ordered transmitters',
                        sent_rx: 'Sent receivers',
                        sent_tx: 'Sent transmitters',
                        invoice: 'Invoice',
                        invoice_number: 'Invoice number',
                        DHL_delivery_number: 'DHL delivery number',
                        DHL_return_number: 'DHL return number',
                        DHL_order: 'DHL order',
                        DHL_order_back: 'DHL order back',
                        closed: 'Closed',
                        wrong: 'Wrong',
                        currency: 'Currency',
                        days: 'Number of days',
                    },
                    new_order: {
                        start_time: 'Start time',
                        end_time: 'End time',
                        start: 'Start date',
                        end: 'End date',
                        add_now: 'Add now',
                        contact_me: 'Use my contact details',
                        contact_person: 'Select contact person',
                        contact_someone: 'Choose contact person',
                        contact_now: 'Add new contact person',
                        delivery_address: 'Delivery address',
                        selected_address: 'Selected address',
                        pick_up: 'I am going to pick it up from Tourguide',
                        take_back: 'I am going to take it back myself',
                        type_address: 'Or type the address',
                        return_address: 'Return address',
                        order_length: 'Order dates',
                        order_dates: 'Order dates',
                        selected_dates: 'Selected dates',
                        devices: 'Devices',
                        invoice_data: 'Invoice data',
                        invoice_date: 'I would like the invoice at',
                        collective_invoice: 'the end of the current month.',
                        asap_invoice: 'the order confirmation.',
                        invoice_data_select: 'I am going to',
                        fill_invoice: 'Fill in the invoice details now.',
                        select_invoice: 'Select the invoice details from existing.',
                        summary: 'Summary',
                        ordered_devices: 'Ordered devices',
                        coupon_code: 'Coupon code',
                        validate: 'Validate',
                        delete_code: 'Delete code',
                        submit: 'Submit order',
                        receiver: 'Receiver',
                        transmitter: 'Transmitter',
                        en: 'All day',
                        de: 'Before noon',
                        du: 'Afternoon'
                    },
                    picker: {
                        startText: 'Start',
                        endText: 'End',
                        startTextOpen: 'Start',
                        endTextOpen: 'End',
                        clearText: 'Clear',
                        applyText: 'Apply',
                    },
                    invoice: {
                        heading: 'Invoices',
                        new: 'New invoice detail',
                        new_title: 'Add new invoice detail',
                        new_subtitle: 'Create new reusable invoice details',
                        edit_title: 'Edit invoice detail',
                        edit_subtitle: 'Edit invoice detail',
                        delete_title: 'Delete invoice detail',
                        delete_subtitle: 'Are you sure you want to delete this?',
                        vat_number: 'VAT number',
                        address: 'Address',
                        name: 'Name',
                        short_name: 'Short name',
                        country: 'Country',
                        postal_code: 'Postal code',
                        city: 'City',
                        company: 'Company?',
                        noInvoiceDetail: 'No invoice details added yet. Add a new using the button on the top right corner!',
                        newFromNav: 'New by VAT number'
                    },
                    '404': {
                        heading: 'Page not found!'
                    },
                    auth: {
                        register: 'Register',
                        successfulActivation: 'Succesful activation!',
                        login: 'Login',
                        email: 'E-mail',
                        password: 'Password',
                        forgot_password: 'Forgot password?',
                        no_account: 'No account? Create one here!',
                        has_account: 'Already have an account? Login here!',
                        first_name: 'First name',
                        last_name: 'Last name',
                        phone: 'Phone number',
                        password_again: 'Password again',
                        not_filled: 'Fill in all the fields to continue!',
                        errors: 'Correct the errors!',
                        successfulRegistration: 'Great news! You successfully registered! We sent you an activation link in email.',
                        goHome: 'Go home',
                        changePassword: 'Change password',
                        newPassword: 'New password',
                        newPasswordAgain: 'New password again',
                    },
                    tour_guides: {
                        title: 'Contacts',
                        first_name: 'First name',
                        last_name: 'Last name',
                        phone: 'Phone',
                        email: 'E-mail',
                        edit_subtitle: 'Edit contact person details',
                        new: 'Add new tour guide',
                        new_title: 'Add new tour guide',
                        new_subtitle: 'Tour guide contact details',
                    },
                    tour_guide: {
                        first_name: 'First name',
                        last_name: 'Last name',
                        email: 'E-mail',
                        phone: 'Phone'
                    }
                },

            },
            hu: {
                translation: {
                    datagrid: {
                        noRowsLabel: 'Nincs sor',
                        columns: 'Oszlopok',
                        filterRowsBtnLabel: 'Sorok szűrése',
                        filterRowsMenuTitle: 'Sorok szűrési menü',
                        filterRowsMenuItemLabel: 'Szűrés',
                        filterListHeader: 'Szűrőlista fejléc',
                        noResultsOverlayLabel: 'Nincs eredmény átfedés',
                        paginationRowsPerPage: 'Oldalankénti sorok',
                        paginationFirstPage: 'Első oldal',
                        paginationLastPage: 'Utolsó oldal',
                        paginationNextPage: 'Következő oldal',
                        paginationPreviousPage: 'Előző oldal',
                        separator: ',',
                        errorOverlayDefaultLabel: 'Alapértelmezett hiba',
                        toolbarDensity: 'Sűrűség',
                        toolbarDensityLabel: 'Sűrűség',
                        toolbarDensityCompact: 'Kompakt',
                        toolbarDensityStandard: 'Normál',
                        toolbarDensityComfortable: 'Kényelmes',
                        toolbarColumns: 'Oszlopok',
                        toolbarColumnsLabel: 'Oszlopok',
                        toolbarFilters: 'Szűrők',
                        toolbarFiltersLabel: 'Szűrők',
                        toolbarFiltersTooltipHide: 'Súgó elrejtése',
                        toolbarFiltersTooltipShow: 'Súgó megjelenítése',
                        toolbarFiltersTooltipActive: 'Aktív súgó',
                        toolbarQuickFilterPlaceholder: 'Gyors szűrő',
                        toolbarQuickFilterLabel: 'Gyors szűrő',
                        toolbarQuickFilterDeleteIconLabel: 'Ikon törlése',
                        toolbarExport: 'Exportálás',
                        toolbarExportLabel: 'Exportálás címkéje',
                        toolbarExportCSV: 'CSV',
                        toolbarExportPrint: 'Nyomtatás',
                        toolbarExportExcel: 'Excel',
                        columnsPanelTextFieldLabel: 'Oszlopok',
                        columnsPanelTextFieldPlaceholder: 'Szöveges mező',
                        columnsPanelDragIconLabel: 'Húzási ikon',
                        columnsPanelShowAllButton: 'Összes gomb megjelenítése',
                        columnsPanelHideAllButton: 'Összes gomb elrejtése',
                        filterPanelAddFilter: 'Szűrő hozzáadása',
                        filterPanelDeleteIconLabel: 'Szűrő törlése',
                        filterPanelLinkOperator: 'Link operátor',
                        filterPanelOperators: 'Panel operátor',
                        filterPanelOperatorAnd: 'ÉS szűrő',
                        filterPanelOperatorOr: 'VAGY szűrő',
                        filterPanelColumns: 'Szűrőoszlopok',
                        filterPanelInputLabel: 'Bemenet',
                        filterPanelInputPlaceholder: 'Panel bemenet',
                        filterOperatorContains: 'Tartalmaz',
                        filterOperatorEquals: 'Egyenlő',
                        filterOperatorStartsWith: 'Kezdődik',
                        filterOperatorEndsWith: 'Végződik',
                        filterOperatorIs: 'Van',
                        filterOperatorNot: 'Nem',
                        filterOperatorAfter: 'Után',
                        filterOperatorOnOrAfter: 'Ekkor vagy utána',
                        filterOperatorBefore: 'Előtt',
                        filterOperatorOnOrBefore: 'Ekkor vagy előtte',
                        filterOperatorIsEmpty: 'Üres',
                        filterOperatorIsNotEmpty: 'Nem üres',
                        filterOperatorIsAnyOf: 'Bármelyik',
                        filterValueAny: 'Bármely',
                        filterValueTrue: 'Igaz',
                        filterValueFalse: 'Hamis',
                        columnMenuLabel: 'Oszlop menü',
                        columnMenuShowColumns: 'Oszlopok megjelenítése',
                        columnMenuFilter: 'Menü szűrő',
                        columnMenuHideColumn: 'Oszlop elrejtése',
                        columnMenuUnsort: 'Rendezés megszüntetése',
                        columnMenuSortAsc: 'Növekvő',
                        columnMenuSortDesc: 'Csökkenő',
                        columnHeaderFiltersTooltipActive: 'Aktív súgó',
                        columnHeaderFiltersLabel: 'Fejléc szűrő',
                        columnHeaderSortIconLabel: 'Rendezés ikon',
                        footerRowSelected: 'Kiválasztott sor',
                        footerTotalRows: 'Összesen',
                        footerTotalVisibleRows: 'Összes látható sor',
                        checkboxSelectionHeaderName: 'Fejléc név kiválasztása',
                        checkboxSelectionSelectAllRows: 'Összes sor kiválasztása',
                        checkboxSelectionUnselectAllRows: 'Összes sor kijelölésének megszüntetése',
                        checkboxSelectionSelectRow: 'Sor kiválasztása',
                        checkboxSelectionUnselectRow: 'Sor kijelölésének megszüntetése',
                        booleanCellTrueLabel: 'Igaz',
                        booleanCellFalseLabel: 'Hamis',
                        actionsCellMore: 'További műveletek',
                        pinToLeft: 'Rögzítés balra',
                        pinToRight: 'Rögzítés jobbra',
                        unpin: 'Rögzítés megszüntetése',
                        treeDataGroupingHeaderName: 'Csoportosított adatok fejléce',
                        treeDataExpand: 'Adatok kibontása',
                        treeDataCollapse: 'Adatok összecsukása',
                        groupingColumnHeaderName: 'Csoport oszlop fejléce',
                        groupColumn: 'Oszlop csoportosítása',
                        unGroupColumn: 'Oszlop csoportosításának megszüntetése',
                        detailPanelToggle: 'Panel kapcsolása',
                        expandDetailPanel: 'Részletek panel kibontása',
                        collapseDetailPanel: 'Részletek panel összecsukása',
                        rowReorderingHeaderName: 'Fejléc név',
                        aggregationMenuItemHeader: 'Menüelem',
                        aggregationFunctionLabelSum: 'Címke összeg',
                        aggregationFunctionLabelAvg: 'Címke átlag',
                        aggregationFunctionLabelMin: 'Címke minimum',
                        aggregationFunctionLabelMax: 'Címke maximum',
                        aggregationFunctionLabelSize: 'Címke méret',
                        MuiTablePagination: 'Oldalszámozás'
                    },
                    footer: {
                        contact: 'Kapcsolat',
                        sections: 'Szakaszok',
                        home: 'Kezdőlap',
                        docs: 'Dokumentumok',
                        privacy: 'Adatkezelési tájékoztató',
                        aszf: 'Felhasználási feltételek',
                        lang: 'Nyelv'
                    },
                    auth: {
                        email: 'E-mail',
                        password: 'Jelszó',
                        successfulActivation: 'Sikeres aktiválás!',
                        forgot_password: 'Elfelejtett jelszó?',
                        no_account: 'Nincs még fiókja? Készítsen egyet itt!',
                        has_account: 'Már van fiókja? Jelentkezzen be itt!',
                        first_name: 'Keresztnév',
                        last_name: 'Vezetéknév',
                        phone: 'Telefonszám',
                        login: 'Bejelentkezés',
                        register: 'Regisztráció',
                        password_again: 'Jelszó újra',
                        not_filled: 'Töltse ki az összes mezőt a folytatáshoz!',
                        errors: 'Javítsa ki a hibákat!',
                        successfulRegistration: 'Nagyszerű hír! Sikeresen regisztrált! Elküldtünk Önnek egy aktivációs linket e-mailben.',
                        goHome: 'Vissza a kezdőlapra',
                        changePassword: 'Jelszó megváltoztatása',
                        newPassword: 'Új jelszó',
                        newPasswordAgain: 'Új jelszó mégegyszer',
                    },
                    tour_guides: {
                        first_name: 'Keresztnév',
                        last_name: 'Vezetéknév',
                        phone: 'Telefonszám',
                        email: 'E-mail',
                        edit_subtitle: 'Kapcsolattartó szerkesztése',
                        title: 'Csoportkísérők',
                        new: 'Csoportkísérő hozzáadása',
                        new_title: 'Csoportkísérő hozzáadása',
                        new_subtitle: 'Csoportkísérő kapcsolattartási adatok',
                    },
                    tour_guide: {
                        first_name: 'Keresztnév',
                        last_name: 'Vezetéknév',
                        phone: 'Telefonszám',
                        email: 'E-mail'
                    },
                    menu: {
                        orders: 'Rendelések',
                        invoices: 'Számlázási adatok',
                        logout: 'Kijelentkezés',
                        sure_logout: 'Biztosan ki szeretne jelentkezni?',
                        tour_guides: 'Csoportkísérők'
                    },
                    general: {
                        login: 'Bejelentkezés',
                        register: 'Regisztráció',
                        cancel: 'Mégsem',
                        send: 'Küldés',
                        or: 'Vagy',
                        next: 'Tovább',
                        edit: 'Szerkesztés',
                        delete: 'Törlés',
                        manage: 'Kezelés',
                        back: 'Vissza',
                        price: 'Ár',
                        save: 'Mentés',
                        add: 'Hozzáadás',
                        emailSent: 'E-mail elküldve!',
                        passwordChanged: 'Jelszó megváltoztatva',
                        dialog_edit: 'Részletek szerkesztése',
                        comment: 'Megjegyzés',
                        comment_description: 'Speciális kérések, stb..., rendelését áttekintésre küldjük, mielőtt elfogadnánk'
                    },
                    errors: {
                        unexpected: "Váratlan hiba történt!",
                        no_code: "Nem található érvényes kuponkód!",
                        validateChars: 'Csak ékezet nélküli kisbetűs karaktereket tartalmazhat (a-z)',
                        validateRequired: 'Ezt a mezőt kötelező kitölteni',
                        validateMinLength: 'Legalább {{minTextLength}} karakter hosszú kell hogy legyen',
                        validatePassword: 'Tartalmaznia kell egy speciális karaktert (!@#$%^&*(),.?":{}|<>) és egy számot',
                        network: 'A szolgáltatás nem elérhető!',
                        wrongPassword: 'Hibás e-mail cím vagy jelszó!',
                        login: 'Bejelentkezési hiba, próbálja később!',
                        validateMaxLength: 'Legfeljebb {{minTextLength}} karakter hosszú lehet',
                        validateEmail: 'Nem megfelelő e-mail cím',
                        validateEqualsPassword: 'Nem egyezik meg a jelszó',
                        validatePhoneNumber: 'Hibás telefonszám',
                        validateVatNumber: 'Az adószám formátuma: 12345678-1-12'
                    },
                    invoice: {
                        heading: 'Számlázási adatok',
                        new: 'Új számlázási cím',
                        new_title: 'Új számlázási adat létrehozása',
                        new_subtitle: 'Hozzon létre új számlázási címet',
                        edit_title: 'Számlázási adat szerkesztése',
                        edit_subtitle: 'Számlázási adat szerkesztése',
                        delete_title: 'Számlázási adat törlése',
                        delete_subtitle: 'Biztosan törölni szeretné ezt?',
                        vat_number: 'Adószám',
                        address: 'Cím',
                        name: 'Név',
                        short_name: 'Rövid név',
                        country: 'Ország',
                        postal_code: 'Irányítószám',
                        city: 'Város',
                        company: 'Cég?',
                        noInvoiceDetail: 'Még nincsenek hozzáadva számlázási adatok. Adj hozzá egy újat a jobb felső sarokban található gomb segítségével!',
                        newFromNav: 'NAV adószám megadása'
                    },
                    orders: {
                        success: 'Sikeres rendelés',
                        successfulOrder: 'Sikeres rendelés!',
                        title: "Rendelések",
                        new: "Új rendelés",
                        order_number: "Rendelésszám",
                        comment: "Megjegyzés",
                        order_status: "Rendelés állapota",
                        tour_type: "Túra típusa",
                        tour_code: "Túra kódja",
                        delivery_date: "Szállítás dátuma",
                        delivery_period: "Szállítási időszak",
                        days_before: "Napok előtte",
                        delivery_type: "Szállítás típusa",
                        delivery_address: "Szállítási cím",
                        delivery_format: "Szállítás formátuma",
                        download_invoice: "Számla letöltése",
                        return_date: "Visszaszállítás dátuma",
                        return_time: 'Visszaszállítás ideje',
                        delivery_time: 'Kiszállítás ideje',
                        invoice_nuumber: 'Számla száma',
                        days_after: "Napok utána",
                        return_type: "Visszaszállítás típusa",
                        return_address: "Visszaszállítási cím",
                        returned_rx: "Visszaszállított vevő",
                        returned_tx: "Visszaszállított adó",
                        order_rx: "Rendelt vevő",
                        order_tx: "Rendelt adó",
                        sent_rx: "Küldött vevő",
                        sent_tx: "Küldött adó",
                        invoice: "Számla",
                        invoice_number: "Számlaszám",
                        DHL_delivery_number: "DHL szállítási szám",
                        DHL_return_number: "DHL visszaszállítási szám",
                        DHL_order: "DHL rendelés",
                        DHL_order_back: "DHL rendelés vissza",
                        closed: "Lezárt",
                        wrong: "Hibás",
                        currency: "Pénznem",
                        days: "Napok száma"
                    },
                    new_order: {
                        start_time: 'Bérlés kezdete',
                        end_time: 'Bérlés vége',
                        start: 'Kezdődátum',
                        end: 'Végdátum',
                        en: 'Egész nap',
                        de: 'Délelőtt',
                        du: 'Délután',
                        contact_me: 'Én leszek a kapcsolattartó',
                        contact_someone: 'Kapcsolattartó kiválasztás',
                        contact_person: 'Csoportkísérő kiválasztása',
                        add_now: 'Új kapcsolattartó hozzáadása',
                        delivery_address: "Szállítási cím",
                        selected_address: "Kiválasztott cím",
                        pick_up: "A Tourguide-tól fogom átvenni",
                        take_back: "Magam fogom visszavinni",
                        type_address: "Vagy írja be a címet",
                        return_address: "Visszaszállítási cím",
                        order_length: "Rendelési időszak",
                        order_dates: "Rendelési dátumok",
                        selected_dates: "Kiválasztott dátumok",
                        devices: "Eszközök",
                        invoice_data: "Számlaadat",
                        invoice_date: "Szeretném a számlát",
                        collective_invoice: "a jelenlegi hónap végén.",
                        asap_invoice: "a rendelés visszaigazolásakor.",
                        invoice_data_select: "A következőt fogom tenni",
                        fill_invoice: "Töltse ki most a számlaadatokat.",
                        select_invoice: "Válassza ki az előre meghatározott számlaadatokat.",
                        summary: "Összegzés",
                        ordered_devices: "Rendelt eszközök",
                        coupon_code: "Kuponkód",
                        validate: "Érvényesít",
                        delete_code: "Kód törlése",
                        submit: "Rendelés elküldése",
                        receiver: "Vevő",
                        transmitter: "Adó"
                    }
                }
            }
        }
    });

export default i18n;