import {useCallback, useContext} from "react";
import {useAuthHeader} from "./hooks.js";
import ConfigContext from "../context/ConfigContext";
import axios from 'axios';
import AuthContext from "../context/AuthContext";
import i18n from "../i18n"
import {useSnackbar} from "notistack";


function useCallDataApi(model) {
    const {headers, formDataHeaders} = useAuthHeader();
    const {logoutUser} = useContext(AuthContext)
    const {baseUrl} = useContext(ConfigContext);
    const snackbar = useSnackbar()

    const url = baseUrl + model + '/';

    const authenticatedAPI = axios.create({
        baseURL: url,
        headers: headers
    })

    const formDataAPI = axios.create({
        baseURL: url,
        headers: formDataHeaders
    })

    const handleError = (e) => {
        console.log(e)
        if (e?.code === 'ERR_NETWORK') snackbar.enqueueSnackbar(i18n.t('errors.network'), {variant: 'error'})
        if (e.response.status === 401) {
            logoutUser()
            return
        }
        if (e.response.status >= 500) {
            snackbar.enqueueSnackbar(i18n.t('errors.unexpected'), {variant: 'error'})
            return
        }
        console.log(e.response?.data)
        throw e
        // return e
        // try {
        //     JSON.stringify(e.response?.data)
        //     const message = []
        //     for (let key of Object.keys(e.response?.data)) {
        //         message.push(`${getFieldName(key)}: ${e.response?.data[key]}`)
        //     }
        //     setExpectedError({error: true, message: message})
        // } catch (e) {
        //     errorMap[e.response?.data?.detail]()
        // }
    }

    const createData = useCallback((data) => {
            return  authenticatedAPI.post("", JSON.stringify(data))
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    const postData = useCallback((slug, data) => {
            return  authenticatedAPI.post(slug, JSON.stringify(data))
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    const getData = useCallback((id = null) => {
            return authenticatedAPI.get(id)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    const updateData = useCallback((id, data) => {
            return authenticatedAPI.patch(`${id}`, JSON.stringify(data))
                .then(r => r?.data)
                .catch(e => handleError(e))

        },
        [authenticatedAPI]
    );

    const deleteData = useCallback((id) => {
            return  authenticatedAPI.delete(`${id}/`)
                .then(r => r)
                .catch(e => handleError(e))
        },
        [authenticatedAPI]
    );

    // ************** FORM DATA STUFF **************

    const formDataCreateData = useCallback((data) => {
            return  formDataAPI.post("", data)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [formDataAPI]
    );

    const formDataUpdateData = useCallback((id, data) => {
            return formDataAPI.patch(`${id}/`, data)
                .then(r => r?.data)
                .catch(e => handleError(e))
        },
        [formDataAPI]
    );

    const getFile = useCallback((id, extension, name = 'data') => {
            return authenticatedAPI.get(`${id}/`, {
                responseType: 'blob',
            }).then(r => {
                const url = window.URL.createObjectURL(r.data);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${name}.${extension}`;
                document.body.appendChild(a);
                a.click();
                a.remove();
            }).catch(e => handleError(e))
        },
        []
    );

    return {
        getData,
        createData,
        updateData,
        deleteData,
        postData,
        formDataCreateData,
        formDataUpdateData,
        getFile
    };
}

export default useCallDataApi;