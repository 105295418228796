import Typography from "@mui/material/Typography";
import {InputAdornment, Stack, TextField, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {useContext, useState} from "react";
import AuthContext from "../../context/AuthContext";
import IconButton from "@mui/material/IconButton";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import Base from "../../components/Base";
import {useTranslation} from "react-i18next";
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
// import confettiiii from "../../utils/confettiiiiiiiiii";


const Login = () => {
    const theme = useTheme()
    const {t} = useTranslation()
    const {loginUser} = useContext(AuthContext)
    const [data, setData] = useState({
        email: '',
        password: ''
    })
    const [passwordVisible, setPasswordVisible] = useState(false)

    // Successful registration confetti! :)
    // confettiiii();

    return <Base>
        <div className="login-container">
            <div className="login-card">
                <form onSubmit={e => {
                    e.preventDefault()
                    loginUser(data)
                }}>
                    <Stack spacing={{xs: 2}}>
                        <Typography variant="headline6" color={theme.palette.primary[600]}>{t('auth.login')}</Typography>
                        <Stack spacing={1} marginBottom={2} marginTop={2}>
                            <Typography variant="caption" color={theme.palette.primary[500]}>{t('auth.email')}</Typography>
                            <TextField value={data?.email} onChange={e => setData({...data, email: e.target.value})} name="email" variant='standard'/>
                            <Typography variant="caption" color={theme.palette.primary[500]}>{t('auth.password')}</Typography>
                            <TextField value={data?.password} onChange={e => setData({...data, password: e.target.value})} name="password" type={passwordVisible ? "text" : "password"} variant='standard' InputProps={{
                                endAdornment: <InputAdornment position="end"><IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                    edge="end"
                                >
                                    {passwordVisible ? <VisibilityOffRoundedIcon /> : <RemoveRedEyeRoundedIcon />}
                                </IconButton>
                                </InputAdornment>
                            }}/>
                        </Stack>
                        <Link to="/forgot-password" style={{color: theme.palette.primary[600]}}>{t('auth.forgot_password')}</Link>
                        <Button endIcon={<LoginRoundedIcon/>} variant="contained" type="submit">{t('auth.login')}</Button>
                        <Link to="/register" style={{color: theme.palette.primary[600]}}>{t('auth.no_account')}</Link>
                    </Stack>
                </form>
            </div>
        </div>
    </Base>
}

export default Login