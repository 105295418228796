import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {Typography, useTheme} from "@mui/material";
import {useContext} from "react";
import OrderContext from "../context/OrderContext";
import {useTranslation} from "react-i18next";


const TgsStepper = () => {
    const {prevStep, stepNumber, goToStep, steps} = useContext(OrderContext)
    const {t} = useTranslation()
    const handleBack = () => {
        prevStep()
    };

    const theme = useTheme()

    return (
        <Box sx={{width: '100%'}} className="reservation-stepper">
            <Stepper alternativeLabel activeStep={stepNumber} style={{color: theme.palette.primary[600]}}>
                {steps.map((label, index) => {
                    return (
                        <Step onClick={() => goToStep(index)} key={`step_${index}`} sx={{
                            cursor: 'pointer',
                            '& .MuiStepLabel-root .Mui-completed': {
                                color: 'primary.600', // circle color (COMPLETED)

                            },
                            '& .MuiStepLabel-root .Mui-active': {
                                color: 'primary.600', // circle color (COMPLETED)
                            },
                            '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                {
                                    color: '#fff', // Just text label (COMPLETED)
                                },
                            '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                {
                                    color: 'common.white', // Just text label (ACTIVE)
                                },
                            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                fill: '#fff', // circle's number (ACTIVE)
                            },
                        }}>
                            <StepLabel><Typography variant='body2' color={theme.palette.primary[400]}>{t(label?.i18n)}</Typography></StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <React.Fragment>
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, marginBottom: 2}}>
                    <Button
                        disabled={stepNumber === 0}
                        onClick={handleBack}
                        sx={{mr: 1}}
                        startIcon={<ArrowBackIosIcon/>}
                    >
                        Vissza
                    </Button>
                </Box>
            </React.Fragment>
        </Box>
    );
}

export default TgsStepper