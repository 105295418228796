import AuthBase from "../../components/AuthenticatedBase";
import {useContext, useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";
import TgsDataGrid from "../../components/DataGrid";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {GridActionsCellItem} from "@mui/x-data-grid";
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import OrderContext from "../../context/OrderContext";

const Orders = () => {
    const [loading, setLoading] = useState(true)
    const {newOrder} = useContext(OrderContext)
    const [data, setData] = useState([])
    const {getData: getOrders, getFile} = useCallDataApi('core/client-orders')
    const history = useHistory()
    const {t} = useTranslation()

    useEffect(() => {
        getOrders().then(o => setData(o)).finally(() => setLoading(false))
    }, [])


    const downloadInvoice = order_id => {
        setLoading(true)
        getFile(`${order_id}/download_invoice`, 'pdf', `INVOICE_${order_id}`)
            .then(r => console.log(r))
            .finally(() => setLoading(false))
    }

    const formatAddress = ({ address }) => {
        if (!address) {
            return '-';
        }
        try {
            const {street_number, street, postal_code, city, country} = address;
            return `${country}, ${postal_code} ${city}, ${street} ${street_number}`;
        } catch (e) {
            return '-'
        }
    }

    const columns = [
        {
            field: "manage",
            headerName: t('general.manage'),
            sortable: false,
            type: "actions",
            getActions: (params) => {
                let menu = []
                if (params.row.invoice_number) menu.push(<GridActionsCellItem
                    icon={<ReceiptRoundedIcon/>}
                    label={t('orders.download_invoice')}
                    onClick={() => downloadInvoice(params.row.id)}
                    showInMenu={true}/>)
                // if (permissions.includes('view_order') && !params.row["closed"]) menu.push(<GridActionsCellItem
                //     icon={<ContentCopyRoundedIcon/>}
                //     label="Rendelés adatainak másolása"
                //     onClick={() => {
                //         console.log(params.row.id)
                //         console.log(orders)
                //         const order = orders.find(o => o.id === params.row.id);
                //         console.log(order)
                //         copyOrderData(order)
                //         enqueueSnackbar('Másolva!', {variant: 'success'})
                //     }}
                //     showInMenu={true}/>)
                // if (permissions.includes('delete_order') && !params.row['closed']) menu.push(<GridActionsCellItem
                //     icon={<DeleteIcon/>}
                //     onClick={() => {
                //         setCurrent(params.row["id"])
                //         toggleDeleteOrderDialog(true)
                //     }}
                //     label="Törlés"
                //     showInMenu={true}
                // />)
                // if (permissions.includes('change_order') && !params.row["closed"]) menu.push(<GridActionsCellItem
                //     icon={<EditIcon/>}
                //     label="Szerkesztés"
                //     onClick={() => {
                //         setCurrent(params.row["id"])
                //         toggleEditOrderDialog(true)
                //     }}
                //     showInMenu={true}/>)
                // if (permissions.includes('change_waybill') && !params.row["closed"]) menu.push(<GridActionsCellItem
                //     icon={<EditIcon/>}
                //     label="Szállítólevél szerkesztése"
                //     onClick={() => {
                //         setCurrent(params.row["id"])
                //         toggleEditWaybillDialog(true)
                //     }}
                //     showInMenu={true}/>)
                // if (permissions.includes('change_sticker') && !params.row["closed"]) menu.push(<GridActionsCellItem
                //     icon={<EditIcon/>}
                //     label="Matrica szerkesztése"
                //     onClick={() => {
                //         setCurrent(params.row["id"])
                //         toggleEditStickerDialog(true)
                //     }}
                //     showInMenu={true}/>)
                // if (permissions.includes('view_waybill')) menu.push(<GridActionsCellItem
                //     icon={<LocalShippingIcon/>}
                //     label="Szállítólevél"
                //     onClick={() => {
                //         getFile(`${params.row.id}/waybill`, 'pdf', params.row['order_number'] + '-waybill').then(r => console.log(r))
                //     }}
                //     showInMenu={true}/>)
                // if (permissions.includes('view_sticker')) menu.push(<GridActionsCellItem
                //     icon={<VignetteIcon/>}
                //     label="Matrica"
                //     onClick={() => {
                //         getFile(`${params.row.id}/sticker`, 'pdf', params.row['order_number'] + '-sticker').then(r => console.log(r))
                //     }}
                //     showInMenu={true}/>)
                // if (permissions.includes('view_doublesticker')) menu.push(<GridActionsCellItem
                //     icon={<VignetteIcon/>}
                //     label="Dupla matrica"
                //     onClick={() => {
                //         getFile(`${params.row.id}/double_sticker`, 'pdf', params.row['order_number'] + '-double-sticker').then(r => console.log(r))
                //     }}
                //     showInMenu={true}/>)
                // if (permissions.includes('change_order') && !params.row["closed"]) menu.push(
                //     <GridActionsCellItem
                //         icon={<ManageAccountsIcon/>}
                //         onClick={() => {
                //             setCurrent(params.row.id)
                //             toggleCourierOrderDialog(true)
                //         }}
                //         label="Futár beállítása"
                //         showInMenu={true}
                //     />)
                // if (permissions.includes('can_track_order')) menu.push(<GridActionsCellItem
                //     icon={<AccountTreeIcon/>}
                //     onClick={() => {
                //         setCurrent(params.row["id"])
                //         getTracking(params.row["id"])
                //         toggleTrackingDialog(true)
                //     }}
                //     label="Nyomonkövetés"
                //     showInMenu={true}
                // />)
                // if (permissions.includes('can_open_order') && permissions.includes('can_close_order')) menu.push(
                //     <GridActionsCellItem
                //         icon={params.row["closed"] ? <LockOpenIcon/> : <LockIcon/>}
                //         onClick={() => {
                //             setCurrent(params.row["id"])
                //             params.row["closed"] ? toggleOpenOrderDialog(true) : toggleCloseOrderDialog(true)
                //         }}
                //         label={params.row["closed"] ? "Kinyitás" : "Lezárás"}
                //         showInMenu={true}
                //     />)
                // if (permissions.includes('can_validate_order')) menu.push(<GridActionsCellItem
                //     icon={<CloseIcon/>}
                //     onClick={() => {
                //         setCurrent(params.row["id"])
                //         toggleValidateDialog(true)
                //     }}
                //     label="Érvénytelenítés"
                //     showInMenu={true}
                // />)

                return menu
            },
        },
        {field: "order_number", headerName: t('orders.order_number'), width: 140},
        {field: "comment", headerName: t('orders.comment'), width: 130},
        // {field: "internal_comment", headerName: "Belsős megjegyzés", width: 130},
        {field: "order_status", headerName: t('orders.order_status'), width: 130},
        // {field: "tour_type", headerName: t('orders.tour_type'), width: 110},
        // {field: "tour_office", headerName: "Megrendelő", width: 110, valueGetter: (params) => {
        //         const to = tourOffices.obj[params.row.tour_office]
        //         if (to) return to?.name || to?.short_name
        //         return ''
        //     }},
        // {field: "tour_code", headerName: t('orders.tour_code'), width: 110},
        // {field: "tour_guide", headerName: "Csoportkísérő", width: 110, valueGetter: (params) => {
        //         const tg = tourGuides.obj[params.row.tour_guide]
        //         if (tg) return `${tg?.last_name} ${tg?.first_name}`
        //         return ''
        //     }},
        {field: "delivery_date", type: "date", headerName: t('orders.delivery_date'), width: 200},
        {
            field: "delivery_time",
            type: "string",
            headerName: t('orders.delivery_time'),
            width: 200,
            renderCell: (params) => (params.row.delivery_time?.slice(0, 5))
        },
        {field: "delivery_period", type: "string", headerName: t('orders.delivery_period'), width: 50},
        // {field: "days_before", headerName: t('orders.days_period'), width: 110},
        {field: "delivery_type", headerName: t('orders.delivery_type'), width: 110},
        {field: "delivery_address", headerName: t('orders.delivery_address'), width: 250, renderCell: p => {
                const str = p.row.delivery_address?.replaceAll("'", "\"")
                try {
                    const address = JSON.parse(str)
                    if (address) return formatAddress(address)
                } catch (e) {
                    return '-'
                }
                return '-'

            }},
        {field: "delivery_format", headerName: t('orders.delivery_format'), width: 110},
        {field: "return_date", type: "date", headerName: t('orders.return_date'), width: 200},
        {
            field: "return_time",
            type: "string",
            headerName: t('orders.return_time'),
            width: 200,
            renderCell: (params) => (params.row.return_time?.slice(0, 5))
        },
        // {field: "days_after", headerName: t('orders.days_after'), width: 110},
        {field: "return_type", headerName: t('orders.return_type'), width: 110},
        {field: "return_address", headerName: t('orders.return_address'), width: 250, renderCell: p => {
                const str = p.row.return_address?.replaceAll("'", "\"")
                try {
                    const address = JSON.parse(str)
                    if (address) return formatAddress(address)
                } catch (e) {
                    return '-'
                }
                return '-'

            }},
        {field: "returned_rx", headerName: t('orders.returned_rx'), width: 110},
        {field: "returned_tx", headerName: t('orders.returned_tx'), width: 110},
        // {
        //     field: "receiver",
        //     headerName: "Vevő",
        //     width: 110,
        //     valueGetter: p => receivers.find(r => r.id === p.row.receiver)?.name
        // },
        // {
        //     field: "transmitter",
        //     headerName: "Adó",
        //     width: 110,
        //     valueGetter: p => transmitters.find(t => t.id === p.row.transmitter)?.name
        // },
        {field: "order_rx", headerName: t('orders.order_rx'), width: 110},
        {field: "order_tx", headerName: t('orders.order_tx'), width: 110},
        {field: "sent_rx", headerName: t('orders.sent_rx'), width: 110},
        {field: "sent_tx", headerName: t('orders.sent_tx'), width: 110},
        {field: "invoice", headerName: t('orders.invoice'), width: 110},
        {field: "invoice_number", headerName: t('orders.invoice_nuumber'), width: 110},
        // {field: "DHL_delivery_number", headerName: t('orders.DHL_delivery_number'), width: 110},
        // {field: "DHL_return_number", headerName: t('orders.DHL_return_number'), width: 110},
        // {field: "DHL_order", headerName: t('orders.DHL_order'), width: 110},
        // {field: "DHL_order_back", headerName: t('orders.DHL_order_back'), width: 110},
        // {
        //     field: "delivery_courier",
        //     headerName: "Kiszállító futár",
        //     width: 140,
        //     valueGetter: p => couriers.find(c => c.id === p.row.delivery_courier)?.name || '-'
        // },
        // {
        //     field: "return_courier",
        //     headerName: "Visszaszállító futár",
        //     width: 140,
        //     valueGetter: p => couriers.find(c => c.id === p.row.return_courier)?.name || '-'
        // },
        // {field: "closed", headerName: t('orders.closed'), type: "boolean", width: 140},
        // {field: "wrong", headerName: t('orders.wrong'), type: "boolean", width: 140},
        // {field: "price", headerName: "Ár", type: "number", width: 140},
        {field: "currency", headerName: t('orders.currency'), width: 110},
        {field: "days", headerName: t('orders.days'), type: "number", width: 140},
        // {field: "short_rent_HUF", headerName: "Rövid bérlés Forintban", type: "number", valueGetter: p => {
        //         const to = tourOffices.obj[p.row.tour_office]
        //         if (to) return to?.short_rent_HUF
        //         return ''
        //     }, width: 200},
        // {field: "long_rent_HUF", headerName: "Hosszú bérlés Forintban", type: "number", valueGetter: p => {
        //         const to = tourOffices.obj[p.row.tour_office]
        //         if (to) return to?.long_rent_HUF
        //         return ''
        //     }, width: 200},
        // {field: "short_rent_EUR", headerName: "Rövid bérlés Euróban", type: "number", valueGetter: p => {
        //         const to = tourOffices.obj[p.row.tour_office]
        //         if (to) return to?.short_rent_EUR
        //         return ''
        //     }, width: 200},
        // {field: "long_rent_EUR", headerName: "Hosszú bérlés Euróban", type: "number", valueGetter: p => {
        //         const to = tourOffices.obj[p.row.tour_office]
        //         if (to) return to?.long_rent_EUR
        //         return ''
        //     }, width: 200},
    ]
    const buttons = [{
        name: t('orders.new'),
        props: {
            variant: 'contained',
            size: 'large',
            endIcon: <AddCircleOutlineRoundedIcon/>,
            onClick: () => {
                history.push('/new-order')
                newOrder()
            }
        }
    }]

    const filterButtons = [
        {
            name: t('orders.new'),
            props: {
                variant: 'contained',
                size: 'small',
                endIcon: <AddCircleOutlineRoundedIcon/>,
                onClick: () => {
                    history.push('/new-order')
                    newOrder()
                }
            }
        }
    ]

    return <AuthBase label={t('orders.title')} buttons={buttons} filterButtonsMobile={filterButtons}>
        <div className="main">
            <TgsDataGrid rows={data} columns={columns}/>
        </div>
        <Loading isLoading={loading}/>
    </AuthBase>
}

export default Orders